@import '../../../css/mixins';

.select-field-container {
  .select-field {
    @include inputBasic;

    &.tbd {
      background-color: $yellow;
    }

    option {
      color: $black;
      background-color: $white;

      &.tbd {
        background-color: $yellow;
      }
    }
  }
}

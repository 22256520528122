.new-property {
  padding-top: 10px;

  .new-property-top {
    margin-bottom: 20px;

    .input-field-container {
      justify-content: flex-start;

      .input-field {
        margin-left: 8px;
        width: 33%;
      }
    }
  }

  .input-field-container, .button {
    margin-bottom: 3px;
  }

  .important-questions-to-ask {
    .input-field-container {
      .input-field {
        width: 33%;
      }
    }
  }
}

.account-profile {
  .loan-officer-info {
    .input-field-container {
      .input-field {
        margin-bottom: 3px;
        width: 50%;

        &.user-role {
          width: 100%;
        }
      }
    }

    .user-role {
      width: 50%;

      .input-field-container {
        .input-field {
          width: 100%;
        }
      }
    }

    .company-logo {
      width: 300px;
      text-align: center;
    }

    .button {
      margin-top: 10px;
    }
  }
}


.pipeline-page {
  width: 100%; }
  .pipeline-page .pipeline-section-nav {
    display: -webkit-flex;
    display: flex;
    margin-top: 10px; }
    .pipeline-page .pipeline-section-nav .pipeline-section-picker {
      margin-right: 10px;
      border-radius: 5px;
      border: 2px solid #969696;
      color: #969696;
      padding: 0 12px;
      height: 22px;
      line-height: 18px;
      cursor: pointer; }
      .pipeline-page .pipeline-section-nav .pipeline-section-picker.time-sensitive {
        border-color: #ffc7ce; }
      .pipeline-page .pipeline-section-nav .pipeline-section-picker.active {
        color: #17375e;
        border-color: #006296; }
        .pipeline-page .pipeline-section-nav .pipeline-section-picker.active.time-sensitive {
          color: #ff0000;
          border-color: #ff0000; }
  .pipeline-page .pipeline-filters {
    margin: 20px 0;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end; }
    .pipeline-page .pipeline-filters .input-field-container {
      width: 30%; }
  .pipeline-page .pipeline-list {
    margin: 10px 0;
    font-size: 12px;
    line-height: 21px; }
    .pipeline-page .pipeline-list .input-field-container .input-field,
    .pipeline-page .pipeline-list .input-field-container .input-field .date-field-input {
      font-size: 12px;
      height: 20px; }
    .pipeline-page .pipeline-list .pipeline-entry-container .pipeline-entry-title {
      margin-bottom: 1px;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: space-between;
              justify-content: space-between;
      -webkit-align-items: center;
              align-items: center; }
      .pipeline-page .pipeline-list .pipeline-entry-container .pipeline-entry-title .button {
        font-size: 14px; }
      .pipeline-page .pipeline-list .pipeline-entry-container .pipeline-entry-title .time-sensitive-badge {
        color: #ff0000;
        height: 100%;
        font-weight: bold; }
    .pipeline-page .pipeline-list .pipeline-entry-container .pipeline-entry {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      border: 2px solid #006296;
      margin-bottom: 12px; }
      .pipeline-page .pipeline-list .pipeline-entry-container .pipeline-entry .pipeline-entry-row {
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: flex-start;
                justify-content: flex-start;
        padding: 2px 0 5px 0; }
        .pipeline-page .pipeline-list .pipeline-entry-container .pipeline-entry .pipeline-entry-row:not(:first-child) {
          border-top: thin solid #d9d9d9; }
        .pipeline-page .pipeline-list .pipeline-entry-container .pipeline-entry .pipeline-entry-row .pipeline-entry-cell {
          -webkit-flex-grow: 1;
                  flex-grow: 1;
          width: 0;
          display: -webkit-flex;
          display: flex;
          -webkit-flex-direction: column;
                  flex-direction: column;
          -webkit-justify-content: flex-end;
                  justify-content: flex-end;
          -webkit-align-items: center;
                  align-items: center;
          margin: 0 5px;
          font-weight: bold; }
          .pipeline-page .pipeline-list .pipeline-entry-container .pipeline-entry .pipeline-entry-row .pipeline-entry-cell > div {
            height: 20px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            max-width: 100%; }
          .pipeline-page .pipeline-list .pipeline-entry-container .pipeline-entry .pipeline-entry-row .pipeline-entry-cell .entry-title {
            font-weight: normal;
            height: 18px; }
  .pipeline-page .right-angle-box {
    display: -webkit-flex;
    display: flex;
    height: 20px;
    position: relative;
    overflow: hidden;
    padding-right: 12px; }
    .pipeline-page .right-angle-box .box-content {
      position: relative;
      height: 100%;
      width: 86px;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: center;
              justify-content: center;
      -webkit-align-items: center;
              align-items: center;
      background-color: #006296;
      color: #ffffff;
      font-weight: normal; }
    .pipeline-page .right-angle-box:before {
      box-sizing: border-box;
      position: absolute;
      content: " ";
      right: 0;
      top: -2px;
      border-left: 12px solid #006296;
      border-top: 12px solid transparent;
      border-bottom: 12px solid transparent; }
    .pipeline-page .right-angle-box.inverse-right-angle-box:not(.unfilled-right-angle-box) .box-content {
      border-width: 2px 0 2px 2px;
      border-style: solid;
      border-color: #006296;
      background-color: #ffffff;
      color: #ff0000; }
      .pipeline-page .right-angle-box.inverse-right-angle-box:not(.unfilled-right-angle-box) .box-content:after {
        box-sizing: border-box;
        position: absolute;
        content: " ";
        width: 1px;
        right: -1px;
        top: -2px;
        height: 20px;
        background-color: #ffffff;
        border-top: 2px solid #006296;
        border-bottom: 2px solid #006296; }
    .pipeline-page .right-angle-box.inverse-right-angle-box:not(.unfilled-right-angle-box):after {
      box-sizing: border-box;
      position: absolute;
      content: " ";
      right: 3px;
      top: 2px;
      border-left: 8px solid #ffffff;
      border-top: 8px solid transparent;
      border-bottom: 8px solid transparent; }
    .pipeline-page .right-angle-box.unfilled-right-angle-box .box-content {
      background-color: #d9d9d9; }
    .pipeline-page .right-angle-box.unfilled-right-angle-box:before {
      border-left-color: #d9d9d9; }
  .pipeline-page .create-name-modal-bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2); }
    .pipeline-page .create-name-modal-bg .create-name-modal {
      position: absolute;
      left: 50%;
      top: 10%;
      -webkit-transform: translateX(-50%);
              transform: translateX(-50%);
      background-color: #ffffff;
      padding: 20px;
      border: 2px solid #006296;
      text-align: right; }
      .pipeline-page .create-name-modal-bg .create-name-modal .input-field-container {
        width: 450px; }
        .pipeline-page .create-name-modal-bg .create-name-modal .input-field-container.import-file-field {
          margin-top: 5px; }
        .pipeline-page .create-name-modal-bg .create-name-modal .input-field-container .input-field {
          -webkit-flex-grow: 1;
                  flex-grow: 1;
          margin-left: 10px; }
      .pipeline-page .create-name-modal-bg .create-name-modal .button {
        margin: 10px 3px 0 0; }
  .pipeline-page .loan-status-container .green-status {
    color: #006600;
    background-color: #99cc33; }
  .pipeline-page .loan-status-container .inverse-green-status {
    color: #ffffff;
    background-color: #006600; }
  .pipeline-page .loan-status-container .red-status {
    color: #ff0000;
    background-color: #ffc7ce; }
  .pipeline-page .loan-status-container .red-on-white-status {
    color: #ff0000;
    background-color: #ffffff; }
  .pipeline-page .loan-status-container .yellow-status {
    color: #000000;
    background-color: #ffff99; }

.text-field-container .text-field {
  border: 1px dotted #969696;
  font-family: 'Roboto', sans-serif;
  font-size: 13px;
  padding: 0 5px;
  height: 22px;
  width: 140px;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  background-color: #f7f7f7;
  text-align: left; }
  .text-field-container .text-field:not(select)[readonly] {
    border: none; }
  .text-field-container .text-field[readonly] {
    outline: none; }
  .text-field-container .text-field:not(select):-moz-read-only {
    border: none; }
  .text-field-container .text-field:not(select):read-only {
    border: none; }
  .text-field-container .text-field:-moz-read-only {
    outline: none; }
  .text-field-container .text-field:read-only {
    outline: none; }
  .text-field-container .text-field[readonly] {
    background-color: #d9d9d9; }
  .text-field-container .text-field:-moz-read-only {
    background-color: #d9d9d9; }
  .text-field-container .text-field:read-only {
    background-color: #d9d9d9; }

:root {
  --reach-tooltip: 1; }

[data-reach-tooltip] {
  z-index: 1;
  pointer-events: none;
  position: absolute;
  padding: 5px 8px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.6);
  font-size: 12px;
  line-height: 16px;
  background: #000000;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  max-width: 330px; }

.training-tip-question-mark {
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  vertical-align: baseline;
  width: 14px;
  height: 14px;
  border-radius: 7px;
  border: none;
  font-size: 11px;
  text-decoration: none;
  font-style: normal;
  font-weight: normal;
  cursor: pointer;
  background-color: #006296;
  color: #ffffff; }

.currency-field-container .currency-field {
  border: 1px dotted #969696;
  font-family: 'Roboto', sans-serif;
  font-size: 13px;
  padding: 0 5px;
  height: 22px;
  width: 140px;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  background-color: #f7f7f7;
  font-family: 'PT Mono', monospace;
  text-align: right;
  transition: all 0.3s ease-in-out; }
  .currency-field-container .currency-field:not(select)[readonly] {
    border: none; }
  .currency-field-container .currency-field[readonly] {
    outline: none; }
  .currency-field-container .currency-field:not(select):-moz-read-only {
    border: none; }
  .currency-field-container .currency-field:not(select):read-only {
    border: none; }
  .currency-field-container .currency-field:-moz-read-only {
    outline: none; }
  .currency-field-container .currency-field:read-only {
    outline: none; }
  .currency-field-container .currency-field[readonly] {
    background-color: #d9d9d9; }
  .currency-field-container .currency-field:-moz-read-only {
    background-color: #d9d9d9; }
  .currency-field-container .currency-field:read-only {
    background-color: #d9d9d9; }

.date-field-container .date-field {
  border: 1px dotted #969696;
  font-family: 'Roboto', sans-serif;
  font-size: 13px;
  padding: 0 5px;
  height: 22px;
  width: 140px;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  background-color: #f7f7f7;
  display: inline-block;
  text-align: right;
  padding: 0;
  border: none; }
  .date-field-container .date-field:not(select)[readonly] {
    border: none; }
  .date-field-container .date-field[readonly] {
    outline: none; }
  .date-field-container .date-field:not(select):-moz-read-only {
    border: none; }
  .date-field-container .date-field:not(select):read-only {
    border: none; }
  .date-field-container .date-field:-moz-read-only {
    outline: none; }
  .date-field-container .date-field:read-only {
    outline: none; }
  .date-field-container .date-field[readonly] {
    background-color: #d9d9d9; }
  .date-field-container .date-field:-moz-read-only {
    background-color: #d9d9d9; }
  .date-field-container .date-field:read-only {
    background-color: #d9d9d9; }
  .date-field-container .date-field .react-datepicker-wrapper {
    width: 100%; }
    .date-field-container .date-field .react-datepicker-wrapper .date-field-input {
      border: 1px dotted #969696;
      font-family: 'Roboto', sans-serif;
      font-size: 13px;
      padding: 0 5px;
      height: 22px;
      width: 140px;
      -webkit-flex-shrink: 0;
              flex-shrink: 0;
      background-color: #f7f7f7;
      width: 100%;
      text-align: right; }
      .date-field-container .date-field .react-datepicker-wrapper .date-field-input:not(select)[readonly] {
        border: none; }
      .date-field-container .date-field .react-datepicker-wrapper .date-field-input[readonly] {
        outline: none; }
      .date-field-container .date-field .react-datepicker-wrapper .date-field-input:not(select):-moz-read-only {
        border: none; }
      .date-field-container .date-field .react-datepicker-wrapper .date-field-input:not(select):read-only {
        border: none; }
      .date-field-container .date-field .react-datepicker-wrapper .date-field-input:-moz-read-only {
        outline: none; }
      .date-field-container .date-field .react-datepicker-wrapper .date-field-input:read-only {
        outline: none; }
      .date-field-container .date-field .react-datepicker-wrapper .date-field-input[readonly] {
        background-color: #d9d9d9; }
      .date-field-container .date-field .react-datepicker-wrapper .date-field-input:-moz-read-only {
        background-color: #d9d9d9; }
      .date-field-container .date-field .react-datepicker-wrapper .date-field-input:read-only {
        background-color: #d9d9d9; }
  .date-field-container .date-field .react-datepicker__day--selected,
  .date-field-container .date-field .react-datepicker__day--keyboard-selected {
    background-color: #006296; }
    .date-field-container .date-field .react-datepicker__day--selected:hover,
    .date-field-container .date-field .react-datepicker__day--keyboard-selected:hover {
      background-color: #17375e; }

.select-field-container .select-field {
  border: 1px dotted #969696;
  font-family: 'Roboto', sans-serif;
  font-size: 13px;
  padding: 0 5px;
  height: 22px;
  width: 140px;
  -webkit-flex-shrink: 0;
          flex-shrink: 0; }
  .select-field-container .select-field:not(select)[readonly] {
    border: none; }
  .select-field-container .select-field[readonly] {
    outline: none; }
  .select-field-container .select-field:not(select):-moz-read-only {
    border: none; }
  .select-field-container .select-field:not(select):read-only {
    border: none; }
  .select-field-container .select-field:-moz-read-only {
    outline: none; }
  .select-field-container .select-field:read-only {
    outline: none; }
  .select-field-container .select-field.tbd {
    background-color: #ffff99; }
  .select-field-container .select-field option {
    color: #000000;
    background-color: #ffffff; }
    .select-field-container .select-field option.tbd {
      background-color: #ffff99; }

.percent-field-container .percent-field {
  border: 1px dotted #969696;
  font-family: 'Roboto', sans-serif;
  font-size: 13px;
  padding: 0 5px;
  height: 22px;
  width: 140px;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  background-color: #f7f7f7;
  font-family: 'PT Mono', monospace;
  text-align: right;
  transition: all 0.3s ease-in-out; }
  .percent-field-container .percent-field:not(select)[readonly] {
    border: none; }
  .percent-field-container .percent-field[readonly] {
    outline: none; }
  .percent-field-container .percent-field:not(select):-moz-read-only {
    border: none; }
  .percent-field-container .percent-field:not(select):read-only {
    border: none; }
  .percent-field-container .percent-field:-moz-read-only {
    outline: none; }
  .percent-field-container .percent-field:read-only {
    outline: none; }
  .percent-field-container .percent-field[readonly] {
    background-color: #d9d9d9; }
  .percent-field-container .percent-field:-moz-read-only {
    background-color: #d9d9d9; }
  .percent-field-container .percent-field:read-only {
    background-color: #d9d9d9; }

.sitenav {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  -webkit-align-items: center;
          align-items: center;
  padding: 0 10px;
  margin-top: 5px;
  margin-bottom: 2px; }
  .sitenav .tier-one-logo {
    height: 30px; }
  .sitenav > .brand {
    -webkit-flex: 1 1;
            flex: 1 1; }
  .sitenav .header-links > a {
    margin-left: 10px; }

.login-page {
  padding: 30px 10px; }
  .login-page .login-title {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    font-weight: bold;
    font-size: 20px; }
  .login-page .login-section {
    margin: 10px auto; }
    .login-page .login-section .login-action {
      width: 400px; }
      .login-page .login-section .login-action .input-field-container {
        margin-bottom: 3px; }
        .login-page .login-section .login-action .input-field-container .input-field {
          width: 70%; }
    .login-page .login-section .error-message {
      color: #ff0000;
      height: 22px;
      min-width: 22px; }
  .login-page .sign-up-section {
    margin: 30px 0; }
    .login-page .sign-up-section .sign-up-content {
      width: 400px;
      text-align: right; }
  .login-page .login-disclaimer {
    margin: 50px 40px 20px;
    font-size: 11px; }

.sign-up .main-title {
  font-size: 26px;
  color: #006296;
  text-align: center;
  margin-top: 5px; }

.sign-up .title {
  font-size: 14px;
  font-weight: bold;
  margin: 20px 0; }

.sign-up .error-messages {
  margin-top: 15px;
  color: #ff0000; }

.sign-up .basic-info-section {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: flex-start;
          align-items: flex-start; }
  .sign-up .basic-info-section .plan-title {
    margin-bottom: 20px; }
  .sign-up .basic-info-section .plan-promo-info {
    color: #0000ff;
    font-weight: bold; }
  .sign-up .basic-info-section .signup-payment-plans {
    width: 50%; }
    .sign-up .basic-info-section .signup-payment-plans .plan-price {
      margin-bottom: 20px;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: flex-start;
              align-items: flex-start; }
      .sign-up .basic-info-section .signup-payment-plans .plan-price input {
        margin-right: 20px; }
  .sign-up .basic-info-section .signup-basic-info .signup-basic-info-group:not(:last-child) {
    margin-bottom: 20px; }
  .sign-up .basic-info-section .signup-basic-info .signup-basic-info-group h4 {
    text-align: center; }
  .sign-up .basic-info-section .signup-basic-info .billing-address-bottom-row {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: nowrap;
            flex-wrap: nowrap; }
    .sign-up .basic-info-section .signup-basic-info .billing-address-bottom-row .input-field-container {
      -webkit-flex-basis: 50%;
              flex-basis: 50%; }
      .sign-up .basic-info-section .signup-basic-info .billing-address-bottom-row .input-field-container:first-child {
        padding-right: 3px; }
  .sign-up .basic-info-section .signup-basic-info .input-field-container {
    margin-bottom: 3px; }
    .sign-up .basic-info-section .signup-basic-info .input-field-container .input-field {
      width: 100%; }

.sign-up .sign-up-receipt .signup-receipt-payment-table {
  width: 650px;
  text-align: left; }
  .sign-up .sign-up-receipt .signup-receipt-payment-table td, .sign-up .sign-up-receipt .signup-receipt-payment-table th {
    padding: 7px 0; }

.sign-up .sign-up-profile .input-field-container {
  margin-bottom: 3px; }
  .sign-up .sign-up-profile .input-field-container .input-field {
    width: 50%; }

.sign-up .stripe-logo {
  text-align: right;
  padding: 50px 25px; }

.sign-up .stripe-logo img {
  width: 300px; }

.spinner {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 2000; }
  .spinner svg {
    position: absolute;
    top: 30%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }

.phone-field-container .phone-field {
  border: 1px dotted #969696;
  font-family: 'Roboto', sans-serif;
  font-size: 13px;
  padding: 0 5px;
  height: 22px;
  width: 140px;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  background-color: #f7f7f7; }
  .phone-field-container .phone-field:not(select)[readonly] {
    border: none; }
  .phone-field-container .phone-field[readonly] {
    outline: none; }
  .phone-field-container .phone-field:not(select):-moz-read-only {
    border: none; }
  .phone-field-container .phone-field:not(select):read-only {
    border: none; }
  .phone-field-container .phone-field:-moz-read-only {
    outline: none; }
  .phone-field-container .phone-field:read-only {
    outline: none; }
  .phone-field-container .phone-field[readonly] {
    background-color: #d9d9d9; }
  .phone-field-container .phone-field:-moz-read-only {
    background-color: #d9d9d9; }
  .phone-field-container .phone-field:read-only {
    background-color: #d9d9d9; }

.stripe-checkout {
  width: 320px; }
  .stripe-checkout .input-field-container {
    margin-bottom: 3px; }
    .stripe-checkout .input-field-container .input-field {
      width: 100%; }
  .stripe-checkout .card-element {
    width: 100%;
    margin-bottom: 3px;
    border: 1px dotted #969696;
    height: 22px;
    padding: 0 3px;
    background-color: #f7f7f7; }
  .stripe-checkout .promo-code {
    margin-bottom: 8px; }

.loan-tool-controls .loan-tool-controls-title {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  padding-bottom: 2px; }
  .loan-tool-controls .loan-tool-controls-title .edit-document-name .text-field-container {
    display: -webkit-inline-flex;
    display: inline-flex; }
    .loan-tool-controls .loan-tool-controls-title .edit-document-name .text-field-container label {
      display: none; }
    .loan-tool-controls .loan-tool-controls-title .edit-document-name .text-field-container .edit-document-name-text-field {
      height: 16px;
      width: 200px; }
  .loan-tool-controls .loan-tool-controls-title .edit-document-name-button {
    padding: 0 6px;
    height: 16px;
    line-height: 16px;
    margin-left: 6px; }

.loan-tool-controls .loan-tool-controls-bar {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  -webkit-align-items: center;
          align-items: center;
  height: 32px;
  padding: 0 20px;
  background-color: #d9d9d9; }
  .loan-tool-controls .loan-tool-controls-bar > .button {
    margin-left: 10px; }
  .loan-tool-controls .loan-tool-controls-bar .loan-tool-left {
    -webkit-flex-grow: 1;
            flex-grow: 1;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center; }
    .loan-tool-controls .loan-tool-controls-bar .loan-tool-left .loan-tool-type {
      color: #6e6e6e;
      font-weight: bold;
      font-size: 15px;
      margin-right: 20px; }
    .loan-tool-controls .loan-tool-controls-bar .loan-tool-left .borrower-names {
      display: -webkit-flex;
      display: flex;
      color: #6e6e6e;
      font-size: 12px; }
      .loan-tool-controls .loan-tool-controls-bar .loan-tool-left .borrower-names > div {
        margin-right: 20px; }
  .loan-tool-controls .loan-tool-controls-bar .my-loans {
    text-decoration: none;
    color: #6e6e6e;
    font-weight: bold;
    font-size: 13px;
    margin-left: 20px; }
  .loan-tool-controls .loan-tool-controls-bar .fnm-help-link {
    margin-right: 5px;
    text-decoration: none; }

.loan-tool-navigation {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 100%;
  font-size: 12px; }
  .loan-tool-navigation .nav-connector-line {
    position: absolute;
    margin: 0; }
    .loan-tool-navigation .nav-connector-line .nav-connector-line-top {
      position: absolute;
      top: -1px;
      left: 0;
      width: 100%;
      height: calc(50% + 2px);
      border-left: 2px #006296 solid;
      border-bottom: 2px #006296 solid; }
    .loan-tool-navigation .nav-connector-line .nav-connector-line-bottom {
      position: absolute;
      top: calc(50% - 1px);
      left: 0;
      width: 100%;
      height: calc(50% + 1px);
      border-right: 2px #006296 solid; }
    .loan-tool-navigation .nav-connector-line.nav-connector-right-oriented .nav-connector-line-top {
      border-left: none;
      border-right: 2px #006296 solid; }
    .loan-tool-navigation .nav-connector-line.nav-connector-right-oriented .nav-connector-line-bottom {
      border-right: none;
      border-left: 2px #006296 solid; }
  .loan-tool-navigation ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: -webkit-flex;
    display: flex; }
  .loan-tool-navigation li {
    height: 24px;
    line-height: 22px; }
  .loan-tool-navigation .nav-bar {
    margin-top: 10px; }
  .loan-tool-navigation .sub-nav {
    margin-left: 110px; }
  .loan-tool-navigation .client-info, .loan-tool-navigation .loan-details {
    width: 110px;
    text-align: center;
    border: 2px #6e6e6e solid;
    border-radius: 5px;
    color: #000000; }
  .loan-tool-navigation .loan-details {
    background-color: #d9d9d9; }
  .loan-tool-navigation .nav-button {
    display: -webkit-flex;
    display: flex;
    position: relative;
    -webkit-align-items: center;
            align-items: center; }
    .loan-tool-navigation .nav-button > a {
      display: block;
      text-decoration: none;
      text-align: center;
      border: 2px #d9d9d9 solid;
      border-radius: 5px;
      width: 144px;
      height: 100%;
      color: #969696; }
      .loan-tool-navigation .nav-button > a:after {
        position: absolute;
        content: " ";
        width: 16px;
        height: 16px;
        border-radius: 8px;
        background-color: #d9d9d9;
        right: 4px;
        top: 50%;
        -webkit-transform: translateY(-50%);
                transform: translateY(-50%); }
    .loan-tool-navigation .nav-button .button {
      height: 100%; }
    .loan-tool-navigation .nav-button .nav-arrow {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      margin: 0 1px 0 2px;
      display: none; }
      .loan-tool-navigation .nav-button .nav-arrow .arrow-body {
        width: 8px;
        height: 12px;
        background-color: #d9d9d9; }
      .loan-tool-navigation .nav-button .nav-arrow .arrow-head {
        border-left: 12px #d9d9d9 solid;
        border-top: 12px transparent solid;
        border-bottom: 12px transparent solid;
        width: 0;
        height: 0; }
    .loan-tool-navigation .nav-button .nav-connector {
      position: relative;
      width: 25px;
      height: 16px;
      line-height: 24px;
      margin-left: -1px;
      margin-right: -1px;
      background-color: #d9d9d9;
      z-index: -1; }
    .loan-tool-navigation .nav-button.active > a {
      color: #17375e; }
    .loan-tool-navigation .nav-button.active .nav-arrow {
      display: -webkit-flex;
      display: flex; }
    .loan-tool-navigation .nav-button.active .nav-connector {
      display: none; }
    .loan-tool-navigation .nav-button.active > a {
      border-color: #006296; }
    .loan-tool-navigation .nav-button.active > a:after {
      background-color: #006296; }
    .loan-tool-navigation .nav-button.marked .nav-arrow {
      display: -webkit-flex;
      display: flex; }
    .loan-tool-navigation .nav-button.marked .nav-connector {
      display: none; }
    .loan-tool-navigation .nav-button.marked > a:after {
      background-color: #99cc33; }

.standard-template {
  position: relative;
  width: 1024px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 20px; }
  .standard-template .standard-template-content {
    position: relative;
    width: 100%; }
    .standard-template .standard-template-content .scroll-to-top {
      position: fixed;
      bottom: 6px;
      right: 50%;
      -webkit-transform: translateX(512px);
              transform: translateX(512px);
      margin: 10px 12px 0 auto;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      -webkit-align-items: center;
              align-items: center;
      border: 1px solid #006296;
      border-radius: 5px;
      padding-top: 2px;
      width: 40px;
      cursor: pointer; }
      @media (max-width: 1024px) {
        .standard-template .standard-template-content .scroll-to-top {
          right: 0;
          -webkit-transform: none;
                  transform: none; } }
      .standard-template .standard-template-content .scroll-to-top .arrow-head {
        border-bottom: 16px #006296 solid;
        border-left: 18px transparent solid;
        border-right: 18px transparent solid;
        width: 0;
        height: 0; }

.applicant-info {
  padding-top: 10px; }
  .applicant-info .applicant-info-table table {
    width: 100%; }
    .applicant-info .applicant-info-table table th {
      text-align: left; }
    .applicant-info .applicant-info-table table td {
      text-align: center;
      padding: 0;
      margin: 0; }
    .applicant-info .applicant-info-table table .text-field, .applicant-info .applicant-info-table table .phone-field {
      -webkit-flex-grow: 1;
              flex-grow: 1; }
  .applicant-info .applicant-info-table .add-borrower-button {
    margin-top: 5px; }
  .applicant-info .edit-borrower .input-field-container {
    margin-bottom: 3px; }
    .applicant-info .edit-borrower .input-field-container .input-field {
      width: 50%; }
  .applicant-info .edit-borrower .button {
    margin-right: 3px;
    margin-bottom: 3px; }

.section {
  position: relative;
  border-color: #d9d9d9;
  border-style: solid;
  border-width: 2px 2px 0 0;
  margin-top: 30px;
  padding: 20px 10px 5px 0; }
  .section .section-name {
    position: absolute;
    background-color: #ffffff;
    top: 0;
    left: 0;
    font-weight: bold;
    font-size: 17px;
    margin-top: -11px;
    padding-right: 10px; }

.number-field-container .number-field {
  border: 1px dotted #969696;
  font-family: 'Roboto', sans-serif;
  font-size: 13px;
  padding: 0 5px;
  height: 22px;
  width: 140px;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  background-color: #f7f7f7;
  text-align: right; }
  .number-field-container .number-field:not(select)[readonly] {
    border: none; }
  .number-field-container .number-field[readonly] {
    outline: none; }
  .number-field-container .number-field:not(select):-moz-read-only {
    border: none; }
  .number-field-container .number-field:not(select):read-only {
    border: none; }
  .number-field-container .number-field:-moz-read-only {
    outline: none; }
  .number-field-container .number-field:read-only {
    outline: none; }
  .number-field-container .number-field[readonly] {
    background-color: #d9d9d9; }
  .number-field-container .number-field:-moz-read-only {
    background-color: #d9d9d9; }
  .number-field-container .number-field:read-only {
    background-color: #d9d9d9; }

.ssn-field-container .ssn-field {
  border: 1px dotted #969696;
  font-family: 'Roboto', sans-serif;
  font-size: 13px;
  padding: 0 5px;
  height: 22px;
  width: 140px;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  background-color: #f7f7f7; }
  .ssn-field-container .ssn-field:not(select)[readonly] {
    border: none; }
  .ssn-field-container .ssn-field[readonly] {
    outline: none; }
  .ssn-field-container .ssn-field:not(select):-moz-read-only {
    border: none; }
  .ssn-field-container .ssn-field:not(select):read-only {
    border: none; }
  .ssn-field-container .ssn-field:-moz-read-only {
    outline: none; }
  .ssn-field-container .ssn-field:read-only {
    outline: none; }
  .ssn-field-container .ssn-field[readonly] {
    background-color: #d9d9d9; }
  .ssn-field-container .ssn-field:-moz-read-only {
    background-color: #d9d9d9; }
  .ssn-field-container .ssn-field:read-only {
    background-color: #d9d9d9; }

.closing-costs-worksheet {
  font-size: 12px; }
  .closing-costs-worksheet .top-border {
    border-top: thin solid #000000; }
  .closing-costs-worksheet .right-border {
    border-right: thin solid #000000; }
  .closing-costs-worksheet .bottom-border {
    border-bottom: thin solid #000000; }
    .closing-costs-worksheet .bottom-border.medium-border {
      border-width: medium; }
  .closing-costs-worksheet .mono-font {
    font-size: 11px; }
  .closing-costs-worksheet .input-field-container .input-field {
    font-size: 11px; }
  .closing-costs-worksheet .section-title {
    font-weight: bold;
    font-size: 13px;
    line-height: 23px;
    margin-right: 3px; }
  .closing-costs-worksheet .closing-costs-main {
    margin-top: 20px; }
    .closing-costs-worksheet .closing-costs-main .button {
      height: 18px;
      line-height: 19px;
      margin: 0 6px; }
    .closing-costs-worksheet .closing-costs-main .closing-costs-main-top .main-top-title {
      width: 210px;
      background-color: #d9d9d9;
      font-size: 15px;
      font-weight: bold;
      height: 22px;
      line-height: 23px;
      padding-left: 3px; }
    .closing-costs-worksheet .closing-costs-main .closing-costs-main-top .main-top-left {
      width: 446px;
      padding-top: 22px;
      border-left: thin solid #000000; }
    .closing-costs-worksheet .closing-costs-main .closing-costs-main-top .main-top-right {
      width: 578px;
      padding-top: 22px;
      border-right: thin solid #000000; }
    .closing-costs-worksheet .closing-costs-main .closing-costs-main-section {
      display: -webkit-flex;
      display: flex; }
      .closing-costs-worksheet .closing-costs-main .closing-costs-main-section .loan-fees .button {
        margin: 0 3px 0 0;
        width: 18px;
        border-radius: 9px;
        padding: 0; }
      .closing-costs-worksheet .closing-costs-main .closing-costs-main-section .loan-fees .edit-fee-list .input-field-container {
        -webkit-flex-grow: 1;
                flex-grow: 1;
        margin-left: -3px; }
        .closing-costs-worksheet .closing-costs-main .closing-costs-main-section .loan-fees .edit-fee-list .input-field-container .input-field {
          width: 100%; }
      .closing-costs-worksheet .closing-costs-main .closing-costs-main-section .closing-costs-row {
        height: 22px;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: space-between;
                justify-content: space-between;
        -webkit-align-items: center;
                align-items: center;
        padding-left: 3px; }
        .closing-costs-worksheet .closing-costs-main .closing-costs-main-section .closing-costs-row.flex-start {
          -webkit-justify-content: flex-start;
                  justify-content: flex-start; }
        .closing-costs-worksheet .closing-costs-main .closing-costs-main-section .closing-costs-row.overflow-hidden {
          overflow: hidden; }
        .closing-costs-worksheet .closing-costs-main .closing-costs-main-section .closing-costs-row .closing-costs-cell {
          display: inline-block;
          padding: 0 3px;
          height: 22px;
          line-height: 23px;
          width: 82px; }
        .closing-costs-worksheet .closing-costs-main .closing-costs-main-section .closing-costs-row .closing-costs-cell-shell {
          display: inline-block;
          height: 22px;
          line-height: 23px;
          width: 82px; }
          .closing-costs-worksheet .closing-costs-main .closing-costs-main-section .closing-costs-row .closing-costs-cell-shell.double-width {
            width: 164px; }
          .closing-costs-worksheet .closing-costs-main .closing-costs-main-section .closing-costs-row .closing-costs-cell-shell.triple-width {
            width: 246px; }
          .closing-costs-worksheet .closing-costs-main .closing-costs-main-section .closing-costs-row .closing-costs-cell-shell.quad-width {
            width: 328px; }
        .closing-costs-worksheet .closing-costs-main .closing-costs-main-section .closing-costs-row .input-field-container .input-field {
          width: 82px;
          padding: 0 3px; }
      .closing-costs-worksheet .closing-costs-main .closing-costs-main-section .closing-costs-main-left {
        display: -webkit-flex;
        display: flex;
        width: 446px;
        border-style: solid;
        border-color: #000000;
        border-width: thin 0 thin thin; }
        .closing-costs-worksheet .closing-costs-main .closing-costs-main-section .closing-costs-main-left .closing-costs-main-left-inner {
          display: -webkit-flex;
          display: flex;
          -webkit-flex-direction: column;
                  flex-direction: column;
          -webkit-justify-content: space-between;
                  justify-content: space-between;
          width: 380px; }
          .closing-costs-worksheet .closing-costs-main .closing-costs-main-section .closing-costs-main-left .closing-costs-main-left-inner .origination-charges .loan-amount-points {
            text-align: center;
            margin-left: -3px; }
      .closing-costs-worksheet .closing-costs-main .closing-costs-main-section .closing-costs-main-right {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-justify-content: space-between;
                justify-content: space-between;
        width: 578px;
        border-style: solid;
        border-color: #000000;
        border-width: thin thin thin 0; }
        .closing-costs-worksheet .closing-costs-main .closing-costs-main-section .closing-costs-main-right .calculating-cash-to-close .main-title {
          font-weight: bold;
          font-size: 15px;
          background-color: #000000;
          color: #ffffff;
          margin-left: -3px;
          padding-left: 3px;
          height: 22px;
          line-height: 23px;
          width: 210px; }
        .closing-costs-worksheet .closing-costs-main .closing-costs-main-section .closing-costs-main-right .calculating-cash-to-close .gray-dashed-border {
          border-width: medium medium 0 medium;
          border-color: #d9d9d9;
          border-style: dashed; }
      .closing-costs-worksheet .closing-costs-main .closing-costs-main-section .origination-charges,
      .closing-costs-worksheet .closing-costs-main .closing-costs-main-section .services-you-cannot-shop-for,
      .closing-costs-worksheet .closing-costs-main .closing-costs-main-section .services-you-can-shop-for,
      .closing-costs-worksheet .closing-costs-main .closing-costs-main-section .taxes-and-other-government-fees,
      .closing-costs-worksheet .closing-costs-main .closing-costs-main-section .prepaids,
      .closing-costs-worksheet .closing-costs-main .closing-costs-main-section .initial-escrow-payment-at-closing,
      .closing-costs-worksheet .closing-costs-main .closing-costs-main-section .other,
      .closing-costs-worksheet .closing-costs-main .closing-costs-main-section .total-other-costs,
      .closing-costs-worksheet .closing-costs-main .closing-costs-main-section .total-closing-costs {
        margin-bottom: 22px; }
    .closing-costs-worksheet .closing-costs-main .services-by {
      margin-top: 22px;
      width: 380px; }
      .closing-costs-worksheet .closing-costs-main .services-by .input-field-container {
        margin-bottom: 3px; }
        .closing-costs-worksheet .closing-costs-main .services-by .input-field-container .input-field {
          width: 50%; }
    .closing-costs-worksheet .closing-costs-main .arm-table {
      display: -webkit-flex;
      display: flex;
      line-height: 22px;
      font-size: 13px; }
      .closing-costs-worksheet .closing-costs-main .arm-table .arm-table-left {
        width: 446px;
        display: -webkit-flex;
        display: flex; }
        .closing-costs-worksheet .closing-costs-main .arm-table .arm-table-left .arm-note-title {
          font-size: 14px;
          font-weight: bold;
          margin-right: 20px; }
      .closing-costs-worksheet .closing-costs-main .arm-table .arm-table-right {
        width: 578px; }
        .closing-costs-worksheet .closing-costs-main .arm-table .arm-table-right .arm-table-row {
          display: -webkit-flex;
          display: flex; }
          .closing-costs-worksheet .closing-costs-main .arm-table .arm-table-right .arm-table-row > div {
            -webkit-flex-basis: 100%;
                    flex-basis: 100%; }
    .closing-costs-worksheet .closing-costs-main .ap-table {
      width: 480px;
      line-height: 22px;
      font-size: 13px; }
      .closing-costs-worksheet .closing-costs-main .ap-table .ap-table-title {
        font-weight: bold;
        font-size: 15px;
        background-color: #000000;
        color: #ffffff;
        padding: 0 3px;
        height: 22px;
        width: 50%; }
      .closing-costs-worksheet .closing-costs-main .ap-table .ap-table-content {
        border: thin solid #000000; }
        .closing-costs-worksheet .closing-costs-main .ap-table .ap-table-content .ap-table-row {
          width: 100%;
          display: -webkit-flex;
          display: flex; }
          .closing-costs-worksheet .closing-costs-main .ap-table .ap-table-content .ap-table-row:not(:first-child) {
            border-top: thin solid #000000; }
          .closing-costs-worksheet .closing-costs-main .ap-table .ap-table-content .ap-table-row > div {
            -webkit-flex-basis: 100%;
                    flex-basis: 100%;
            height: 22px;
            padding: 0 3px; }
  .closing-costs-worksheet .transfer-taxes-calculator,
  .closing-costs-worksheet .prepaids-calculator {
    font-size: 13px;
    width: 50%; }
    .closing-costs-worksheet .transfer-taxes-calculator .input-field-container,
    .closing-costs-worksheet .prepaids-calculator .input-field-container {
      margin-bottom: 3px; }
      .closing-costs-worksheet .transfer-taxes-calculator .input-field-container .input-field,
      .closing-costs-worksheet .prepaids-calculator .input-field-container .input-field {
        width: 40%;
        font-size: 13px; }
    .closing-costs-worksheet .transfer-taxes-calculator .button,
    .closing-costs-worksheet .prepaids-calculator .button {
      margin: 10px 3px 10px 0; }

.loan-picker-navigation {
  display: -webkit-flex;
  display: flex;
  position: relative;
  -webkit-align-items: center;
          align-items: center;
  font-size: 12px;
  margin-top: 20px;
  margin-left: 110px;
  margin-bottom: 10px; }
  .loan-picker-navigation .button {
    height: 24px; }
  .loan-picker-navigation .loan-tab {
    position: relative;
    border: 2px solid #d9d9d9;
    height: 24px;
    line-height: 22px;
    border-radius: 5px;
    width: 100px;
    cursor: pointer;
    text-align: center;
    color: #6e6e6e; }
    .loan-picker-navigation .loan-tab:after {
      position: absolute;
      content: " ";
      width: 16px;
      height: 16px;
      border-radius: 8px;
      background-color: #d9d9d9;
      right: 4px;
      top: 50%;
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%); }
    .loan-picker-navigation .loan-tab.active {
      color: #17375e;
      border-color: #006296; }
      .loan-picker-navigation .loan-tab.active:after {
        background-color: #006296; }
    .loan-picker-navigation .loan-tab.marked:after {
      background-color: #99cc33; }
    .loan-picker-navigation .loan-tab:not(:first-child) {
      margin-left: 23px; }
      .loan-picker-navigation .loan-tab:not(:first-child) .nav-arrow {
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center;
        margin: 0 1px 0 2px;
        display: none;
        position: absolute;
        top: -2px;
        left: -25px;
        pointer-events: none; }
        .loan-picker-navigation .loan-tab:not(:first-child) .nav-arrow .arrow-body {
          width: 8px;
          height: 12px;
          background-color: #d9d9d9; }
        .loan-picker-navigation .loan-tab:not(:first-child) .nav-arrow .arrow-head {
          border-left: 12px #d9d9d9 solid;
          border-top: 12px transparent solid;
          border-bottom: 12px transparent solid;
          width: 0;
          height: 0; }
      .loan-picker-navigation .loan-tab:not(:first-child) .nav-connector {
        position: absolute;
        left: -25px;
        top: 2px;
        width: 23px;
        height: 16px;
        line-height: 24px;
        background-color: #d9d9d9;
        pointer-events: none; }
      .loan-picker-navigation .loan-tab:not(:first-child).active .nav-arrow {
        display: -webkit-flex;
        display: flex; }
      .loan-picker-navigation .loan-tab:not(:first-child).active .nav-connector {
        display: none; }
      .loan-picker-navigation .loan-tab:not(:first-child).marked .nav-arrow {
        display: -webkit-flex;
        display: flex; }
      .loan-picker-navigation .loan-tab:not(:first-child).marked .nav-connector {
        display: none; }
  .loan-picker-navigation .loan-tab-shell {
    position: relative;
    margin-left: 23px; }
    .loan-picker-navigation .loan-tab-shell .nav-connector {
      position: absolute;
      left: -23px;
      top: 4px;
      width: 23px;
      height: 16px;
      line-height: 24px;
      background-color: #d9d9d9;
      pointer-events: none; }

.no-loans,
.no-borrowers,
.no-pricing-lock-period {
  font-size: 15px;
  font-weight: bold;
  margin: 20px 0; }

.debt-info-account-overview .account-overview-top {
  margin-top: 20px; }
  .debt-info-account-overview .account-overview-top .input-field-container {
    margin-bottom: 3px;
    width: 80%; }

.debt-info-account-overview .account-overview-loan-tabs {
  margin-bottom: 10px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
          justify-content: flex-start; }
  .debt-info-account-overview .account-overview-loan-tabs .loan-tab {
    margin-right: 10px;
    border-radius: 5px;
    border: 2px solid #969696;
    color: #969696;
    padding: 0 12px;
    height: 22px;
    line-height: 18px;
    cursor: pointer; }
    .debt-info-account-overview .account-overview-loan-tabs .loan-tab.active {
      color: #17375e;
      border-color: #006296; }

.debt-info-account-overview .account-table .account-table-header {
  position: relative;
  margin-bottom: 20px; }
  .debt-info-account-overview .account-table .account-table-header .credit-score {
    position: absolute;
    right: 0;
    top: 0; }

.debt-info-account-overview .account-table table {
  width: 100%;
  font-size: 10px;
  table-layout: fixed; }
  .debt-info-account-overview .account-table table th:nth-child(-n+4), .debt-info-account-overview .account-table table th:nth-child(12) {
    width: 46px; }
  .debt-info-account-overview .account-table table th:last-child {
    width: 74px; }
  .debt-info-account-overview .account-table table th:nth-child(14) {
    width: 100px; }
  .debt-info-account-overview .account-table table td {
    text-align: center; }
  .debt-info-account-overview .account-table table .input-field {
    font-size: 10px;
    width: 100%;
    padding: 0 2px; }
  .debt-info-account-overview .account-table table .disposition-field {
    width: 110px; }
  .debt-info-account-overview .account-table table .button {
    font-size: 11px;
    padding: 0 5px; }

.debt-info-account-overview .account-table .loan-dti-section {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  margin: 0 20% 10px 20%; }
  .debt-info-account-overview .account-table .loan-dti-section .loan-dti {
    text-align: center;
    font-size: 12px;
    font-weight: bold;
    margin-top: 20px; }
    .debt-info-account-overview .account-table .loan-dti-section .loan-dti > div {
      display: inline-block;
      margin-right: 20px; }

.debt-info-add-account {
  padding-top: 10px; }
  .debt-info-add-account .divider {
    margin-bottom: 5px; }
  .debt-info-add-account .input-field-container {
    margin-bottom: 3px; }
    .debt-info-add-account .input-field-container .input-field {
      width: 50%; }
  .debt-info-add-account .button {
    margin-top: 10px;
    margin-right: 3px; }
  .debt-info-add-account .residence-reserve-calculator .section-title {
    font-weight: bold;
    margin: 10px 0; }

.down-payment {
  padding-top: 10px; }
  .down-payment .input-field-container {
    margin-bottom: 3px;
    font-size: 12px; }
    .down-payment .input-field-container .input-field {
      font-size: 12px; }
  .down-payment .button {
    margin-bottom: 3px; }
  .down-payment .down-payment-section-title {
    font-weight: bold;
    margin: 10px 0; }
  .down-payment .down-payment-all-loans-the-same {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    margin-bottom: 10px; }
    .down-payment .down-payment-all-loans-the-same > div {
      margin-right: 10px; }
  .down-payment .down-payment-all-loans-same-emd-seller-concessions {
    margin-bottom: 10px; }
    .down-payment .down-payment-all-loans-same-emd-seller-concessions > div {
      margin-bottom: 5px; }
  .down-payment .configure-dti {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    line-height: 22px; }
    .down-payment .configure-dti .button {
      margin-left: 10px; }
  .down-payment .down-payment-top .input-field {
    width: 50%; }
  .down-payment .down-payment-edit-modal {
    width: 50%; }
    .down-payment .down-payment-edit-modal .input-field {
      width: 40%; }
    .down-payment .down-payment-edit-modal .button {
      margin: 10px 3px 10px 0; }
  .down-payment .down-payment-calculation-result .input-field-container .input-field.red-outlined {
    color: #ff0000;
    background-color: #ffffff;
    border: 1px solid #ff0000; }
  .down-payment .down-payment-calculation-result .input-field-container .input-field.yellow-highlighted {
    background-color: #ffff99; }
  .down-payment .loan-calculator-button {
    margin: 10px 0; }
  .down-payment .refinance-loan-calculator .input-field-container {
    width: 50%; }
    .down-payment .refinance-loan-calculator .input-field-container .input-field {
      width: 40%; }
  .down-payment .refinance-loan-calculator .button {
    margin: 10px 3px 10px 0; }

.income {
  padding-top: 10px; }
  .income .income-information {
    margin-bottom: 40px; }
    .income .income-information table {
      width: 100%; }
      .income .income-information table th {
        font-size: 13px; }
      .income .income-information table .input-field {
        width: 100%; }
    .income .income-information .button {
      margin-right: 3px; }
    .income .income-information .divider {
      margin: 20px 0; }
  .income .edit-borrower-income .edit-borrower-income-top {
    width: 50%;
    margin-bottom: 20px; }
    .income .edit-borrower-income .edit-borrower-income-top .input-field-container {
      margin-bottom: 3px; }
      .income .edit-borrower-income .edit-borrower-income-top .input-field-container .input-field {
        width: 50%; }
  .income .edit-borrower-income .edit-borrower-income-calculator {
    margin: 30px 0 20px 0; }
    .income .edit-borrower-income .edit-borrower-income-calculator table {
      width: 100%;
      table-layout: fixed;
      margin-bottom: 20px; }
      .income .edit-borrower-income .edit-borrower-income-calculator table .input-field {
        width: 100%; }
  .income .edit-borrower-income .button {
    margin-right: 3px; }
  .income .income-employment-information .employment-information-fields {
    width: 70%;
    margin-bottom: 10px; }
    .income .income-employment-information .employment-information-fields .input-field-container {
      margin-bottom: 3px; }
      .income .income-employment-information .employment-information-fields .input-field-container .input-field {
        width: 50%; }
  .income .income-employment-information .button {
    margin-right: 3px; }
  .income .edit-additional-income .additional-income-fields {
    width: 70%;
    margin-bottom: 10px; }
    .income .edit-additional-income .additional-income-fields .input-field-container {
      margin-bottom: 3px; }
      .income .edit-additional-income .additional-income-fields .input-field-container .input-field {
        width: 50%; }
  .income .edit-additional-income .social-security-header {
    margin: 20px 0 10px 0;
    font-weight: bold; }
  .income .edit-additional-income .button {
    margin-right: 3px; }
  .income .income-summary .input-field-container {
    margin-bottom: 3px; }

.schedule-e-calculator {
  padding-top: 10px; }
  .schedule-e-calculator .input-field-container, .schedule-e-calculator .button {
    margin-bottom: 3px;
    margin-right: 3px; }
  .schedule-e-calculator .tax-returns {
    margin-bottom: 10px; }
    .schedule-e-calculator .tax-returns .tax-return-label {
      height: 22px;
      line-height: 22px;
      margin-bottom: 3px; }
    .schedule-e-calculator .tax-returns .tax-return-totals {
      margin-top: 5px; }

.invest-reo {
  padding-top: 10px; }
  .invest-reo .invest-reo-table {
    width: 100%;
    table-layout: fixed;
    font-size: 12px;
    margin-bottom: 3px; }
    .invest-reo .invest-reo-table th {
      vertical-align: bottom;
      font-size: 12px;
      width: auto; }
      .invest-reo .invest-reo-table th:last-child {
        width: 50px; }
    .invest-reo .invest-reo-table td .input-field-container .input-field {
      width: 100%;
      font-size: 12px; }
  .invest-reo .investment-calculator .input-field-container {
    margin-bottom: 3px; }
    .invest-reo .investment-calculator .input-field-container .input-field {
      width: 50%; }
  .invest-reo .investment-calculator .button {
    margin-right: 3px; }

.le-price-engine-options {
  padding-top: 10px; }
  .le-price-engine-options .le-price-engine-options-main {
    padding-top: 10px; }
    .le-price-engine-options .le-price-engine-options-main .le-price-engine-options-top {
      margin: 20px 0;
      width: 30%; }
      .le-price-engine-options .le-price-engine-options-main .le-price-engine-options-top .input-field-container {
        margin-bottom: 3px; }
    .le-price-engine-options .le-price-engine-options-main .le-price-engine-options-loans table {
      width: 100%;
      table-layout: fixed;
      margin: 10px 0; }
      .le-price-engine-options .le-price-engine-options-main .le-price-engine-options-loans table th:first-child,
      .le-price-engine-options .le-price-engine-options-main .le-price-engine-options-loans table th:last-child {
        width: 50px; }
      .le-price-engine-options .le-price-engine-options-main .le-price-engine-options-loans table td {
        min-width: 60px; }
      .le-price-engine-options .le-price-engine-options-main .le-price-engine-options-loans table .input-field {
        width: 100%; }
    .le-price-engine-options .le-price-engine-options-main .le-price-engine-options-loans .loan-processor-data-table {
      font-size: 12px; }
      .le-price-engine-options .le-price-engine-options-main .le-price-engine-options-loans .loan-processor-data-table table th:last-child {
        width: auto; }
      .le-price-engine-options .le-price-engine-options-main .le-price-engine-options-loans .loan-processor-data-table table .input-field-container {
        width: 100%; }
        .le-price-engine-options .le-price-engine-options-main .le-price-engine-options-loans .loan-processor-data-table table .input-field-container .input-field {
          font-size: 12px;
          width: 100%; }
  .le-price-engine-options .le-price-engine-options-edit-loan {
    width: 50%; }
    .le-price-engine-options .le-price-engine-options-edit-loan .input-field-container {
      margin-bottom: 3px; }
      .le-price-engine-options .le-price-engine-options-edit-loan .input-field-container .input-field {
        width: 40%; }
    .le-price-engine-options .le-price-engine-options-edit-loan .button {
      margin: 10px 3px 10px 0; }

.le-price-engine {
  padding-top: 10px; }
  .le-price-engine .le-price-engine-title {
    font-weight: bold;
    margin: 10px 0; }
  .le-price-engine .le-price-engine-main {
    padding-top: 10px; }
    .le-price-engine .le-price-engine-main .le-price-engine-main-top .le-price-engine-main-warning {
      margin-bottom: 10px;
      font-weight: bold;
      color: #ff0000; }
    .le-price-engine .le-price-engine-main .le-price-engine-main-top .input-field {
      width: 40%; }
    .le-price-engine .le-price-engine-main .input-field-container {
      margin-bottom: 3px; }
    .le-price-engine .le-price-engine-main .le-price-engine-main-loans .input-field-container {
      font-size: 11px; }
      .le-price-engine .le-price-engine-main .le-price-engine-main-loans .input-field-container .input-field {
        font-size: 11px;
        width: 50%; }
  .le-price-engine .le-price-engine-edit-loan .input-field-container {
    margin-bottom: 3px; }
  .le-price-engine .le-price-engine-edit-loan .button {
    margin: 10px 3px 10px 0; }
  .le-price-engine .le-price-engine-edit-loan .le-price-engine-rate-calculator {
    margin: 20px 0; }

.time-field-container .time-field {
  border: 1px dotted #969696;
  font-family: 'Roboto', sans-serif;
  font-size: 13px;
  padding: 0 5px;
  height: 22px;
  width: 140px;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  background-color: #f7f7f7;
  display: inline-block;
  text-align: right;
  padding: 0;
  border: none; }
  .time-field-container .time-field:not(select)[readonly] {
    border: none; }
  .time-field-container .time-field[readonly] {
    outline: none; }
  .time-field-container .time-field:not(select):-moz-read-only {
    border: none; }
  .time-field-container .time-field:not(select):read-only {
    border: none; }
  .time-field-container .time-field:-moz-read-only {
    outline: none; }
  .time-field-container .time-field:read-only {
    outline: none; }
  .time-field-container .time-field[readonly] {
    background-color: #d9d9d9; }
  .time-field-container .time-field:-moz-read-only {
    background-color: #d9d9d9; }
  .time-field-container .time-field:read-only {
    background-color: #d9d9d9; }
  .time-field-container .time-field .react-datepicker-wrapper {
    width: 100%; }
    .time-field-container .time-field .react-datepicker-wrapper .time-field-input {
      border: 1px dotted #969696;
      font-family: 'Roboto', sans-serif;
      font-size: 13px;
      padding: 0 5px;
      height: 22px;
      width: 140px;
      -webkit-flex-shrink: 0;
              flex-shrink: 0;
      background-color: #f7f7f7;
      width: 100%;
      text-align: right; }
      .time-field-container .time-field .react-datepicker-wrapper .time-field-input:not(select)[readonly] {
        border: none; }
      .time-field-container .time-field .react-datepicker-wrapper .time-field-input[readonly] {
        outline: none; }
      .time-field-container .time-field .react-datepicker-wrapper .time-field-input:not(select):-moz-read-only {
        border: none; }
      .time-field-container .time-field .react-datepicker-wrapper .time-field-input:not(select):read-only {
        border: none; }
      .time-field-container .time-field .react-datepicker-wrapper .time-field-input:-moz-read-only {
        outline: none; }
      .time-field-container .time-field .react-datepicker-wrapper .time-field-input:read-only {
        outline: none; }
      .time-field-container .time-field .react-datepicker-wrapper .time-field-input[readonly] {
        background-color: #d9d9d9; }
      .time-field-container .time-field .react-datepicker-wrapper .time-field-input:-moz-read-only {
        background-color: #d9d9d9; }
      .time-field-container .time-field .react-datepicker-wrapper .time-field-input:read-only {
        background-color: #d9d9d9; }
  .time-field-container .time-field .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
    background-color: #006296; }
    .time-field-container .time-field .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
      background-color: #17375e; }

.loan-type {
  padding-top: 10px; }
  .loan-type .loan-type-main-page {
    padding-top: 10px; }
    .loan-type .loan-type-main-page .loan-type-section {
      display: -webkit-flex;
      display: flex; }
      .loan-type .loan-type-main-page .loan-type-section .input-field {
        width: 60%;
        margin-bottom: 3px; }
      .loan-type .loan-type-main-page .loan-type-section .button {
        margin: 10px 3px 10px 0; }
      .loan-type .loan-type-main-page .loan-type-section .loan-type-header {
        margin: 10px 0;
        font-weight: bold; }
  .loan-type .edit-loan-type .input-field-container {
    margin-bottom: 3px; }
    .loan-type .edit-loan-type .input-field-container .input-field {
      width: 50%; }
  .loan-type .edit-loan-type .button {
    margin: 10px 3px 10px 0; }

.new-property {
  padding-top: 10px; }
  .new-property .new-property-top {
    margin-bottom: 20px; }
    .new-property .new-property-top .input-field-container {
      -webkit-justify-content: flex-start;
              justify-content: flex-start; }
      .new-property .new-property-top .input-field-container .input-field {
        margin-left: 8px;
        width: 33%; }
  .new-property .input-field-container, .new-property .button {
    margin-bottom: 3px; }
  .new-property .important-questions-to-ask .input-field-container .input-field {
    width: 33%; }

.reserves {
  padding-top: 10px; }
  .reserves .reserves-middle-section {
    margin: 20px 0;
    width: 70%; }
  .reserves .reserves-borrower-entry {
    padding-bottom: 5px; }
    .reserves .reserves-borrower-entry table {
      width: 100%;
      table-layout: fixed;
      margin-bottom: 10px; }
      .reserves .reserves-borrower-entry table .button {
        margin-right: 0; }
      .reserves .reserves-borrower-entry table th:nth-last-child(-n+2) {
        width: 45px; }
        .reserves .reserves-borrower-entry table th:nth-last-child(-n+2):last-child {
          width: 60px; }
      .reserves .reserves-borrower-entry table td {
        width: 12%; }
      .reserves .reserves-borrower-entry table .input-field {
        width: 100%; }
  .reserves .reserves-summary table {
    width: 100%; }
    .reserves .reserves-summary table td {
      white-space: nowrap; }
    .reserves .reserves-summary table .input-field {
      width: 100%; }
  .reserves .total-reserve-calculator table {
    width: 100%; }
  .reserves .total-reserve-calculator .input-field {
    width: 100%; }
  .reserves .total-reserve-calculator .reserve-property-table td {
    width: 11%; }
  .reserves .total-reserve-calculator .total-reserve-calculator-jumbo-reserves {
    margin: 30px 0; }
    .reserves .total-reserve-calculator .total-reserve-calculator-jumbo-reserves th {
      text-align: left;
      width: 20%; }
    .reserves .total-reserve-calculator .total-reserve-calculator-jumbo-reserves td {
      width: 16%; }
  .reserves .total-reserve-calculator .total-reserve-calculator-bottom-table th {
    text-align: left;
    width: 20%; }
  .reserves .total-reserve-calculator .total-reserve-calculator-bottom-table td {
    width: 16%; }
  .reserves .fnma-reserves-calculation table {
    border-spacing: 0;
    border-collapse: collapse;
    width: 60%;
    border: 1px solid #000000;
    margin-bottom: 15px; }
    .reserves .fnma-reserves-calculation table td {
      border: 1px solid #000000;
      padding: 1px 3px; }
  .reserves .reserves-edit-reserve .input-field-container {
    margin-bottom: 3px; }
  .reserves .reserves-edit-reserve .button {
    margin: 10px 3px 10px 0; }
  .reserves .reserves-edit-reserve .reserves-edit-reserve-title {
    height: 22px;
    margin-bottom: 3px;
    font-weight: bold; }
  .reserves .reserves-equity-from-sale-calculator table {
    width: 100%; }
    .reserves .reserves-equity-from-sale-calculator table table td:first-child {
      width: 22%; }
  .reserves .reserves-equity-from-sale-calculator .input-field {
    width: 100%; }
  .reserves .reserves-equity-from-sale-calculator .button {
    margin: 10px 3px 10px 0; }

.selling-property {
  padding-top: 20px; }
  .selling-property .input-field-container {
    margin-bottom: 3px; }
  .selling-property .selling-property-section .input-field-container .input-field {
    width: 33%; }
  .selling-property .important-questions-to-ask .input-field-container .input-field {
    width: 33%; }

.summary-container {
  line-height: 22px;
  margin-bottom: 20px;
  margin-top: 20px;
  width: 100%; }
  .summary-container .summary-tab-container {
    margin-bottom: 20px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center; }
    .summary-container .summary-tab-container .summary-tab {
      margin: 0 5px;
      border-radius: 5px;
      border: 2px solid #969696;
      color: #969696;
      padding: 0 12px;
      height: 22px;
      line-height: 18px;
      cursor: pointer; }
      .summary-container .summary-tab-container .summary-tab.active {
        color: #17375e;
        border-color: #006296; }

.summary-container table {
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
  margin: 0;
  table-layout: fixed; }

.summary-container table td {
  text-align: left;
  vertical-align: top;
  height: 22px;
  line-height: 22px;
  padding: 0 4px; }

/* Formats */
.summary-container .bold {
  font-weight: bold; }

.summary-container .italic {
  font-style: italic; }

.summary-container .underline {
  text-decoration: underline; }

.summary-container .normal-font {
  font-size: 13px; }

.summary-container .big-font {
  font-size: 16px; }

.summary-container .bigger-font {
  font-size: 18px; }

.summary-container .biggest-font {
  font-size: 20px; }

.summary-container .small-font {
  font-size: 12px; }

.summary-container .smaller-font {
  font-size: 11px; }

.summary-container .smallest-font {
  font-size: 10px; }

.summary-container .green-text {
  color: #006600; }

.summary-container .blue-text {
  color: #0000ff; }

.summary-container .light-olive-bg {
  background-color: #ebf1de; }

.summary-container .gray-bg {
  background-color: #d9d9d9; }

.summary-container .flex-space-between {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between; }

.summary-container .flex-column-space-between {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between; }

.summary-container .flex-start {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
          justify-content: flex-start; }

.summary-container .flex-start-with-spacing {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
          justify-content: flex-start; }

.summary-container .flex-start-with-spacing > div {
  margin-right: auto; }

/* Borders - Order matters */
/* Using keywords (i.e. thin) due to chrome rendering issue */
.summary-container .with-border,
.summary-container .with-td-border td {
  border: thin solid #000000; }

.summary-container .no-border,
.summary-container tbody td.no-border {
  border: none; }

.summary-container .top-border,
.summary-container tbody td.top-border {
  border-top: thin solid #000000; }

.summary-container .right-border,
.summary-container tbody td.right-border {
  border-right: thin solid #000000; }

.summary-container .bottom-border,
.summary-container tbody td.bottom-border {
  border-bottom: thin solid #000000; }

.summary-container .left-border,
.summary-container tbody td.left-border {
  border-left: thin solid #000000; }

.summary-container .double-top-border,
.summary-container tbody td.double-top-border {
  border-top: medium double #000000; }

.summary-container .double-bottom-border,
.summary-container tbody td.double-bottom-border {
  border-bottom: medium double #000000; }

.summary-container .thick-top-border,
.summary-container tbody td.thick-top-border {
  border-top: medium solid #000000; }

.summary-container .thick-bottom-border,
.summary-container tbody td.thick-bottom-border {
  border-bottom: medium solid #000000; }

.summary-container .thick-left-border,
.summary-container tbody td.thick-left-border {
  border-left: medium solid #000000; }

.summary-container .no-top-border,
.summary-container tbody td.no-top-border {
  border-top: none; }

.summary-container .no-right-border,
.summary-container tbody td.no-right-border {
  border-right: none; }

.summary-container .no-bottom-border,
.summary-container tbody td.no-bottom-border {
  border-bottom: none; }

.summary-container .no-left-border,
.summary-container tbody td.no-left-border {
  border-left: none; }

/* Alignments */
.summary-container td.center-align {
  text-align: center !important; }

.summary-container td.left-align {
  text-align: left !important; }

.summary-container td.right-align {
  text-align: right !important; }

.summary-container td.middle-align {
  vertical-align: middle; }

/* Signatures */
.summary-container .signature-container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  height: 100%; }

.summary-container .signature-container .signature-line {
  border-bottom: thin solid #000000; }

.summary-container .signature-container .signature-line,
.summary-container .signature-container .signature-block {
  display: -webkit-flex;
  display: flex;
  width: 45%; }

.summary-container .signature-container .signature-block > :first-child {
  -webkit-flex-grow: 3;
          flex-grow: 3; }

.summary-container .signature-container .signature-block > :not(:first-child) {
  -webkit-flex-grow: 1;
          flex-grow: 1; }

.summary-container .summary-header {
  font-weight: bold;
  font-size: 16px;
  color: #ffffff;
  background-color: #000000; }

.summary-container td .indent {
  padding-left: 1em; }

/* Summary sheet specifics */
.summary-sheet {
  font-size: 13px;
  margin: 15px auto 25px auto;
  width: 960px;
  /* Inputs */ }
  .summary-sheet .refinance-anti-steering {
    font-size: 12px;
    padding: 0 1px;
    text-align: center; }
  .summary-sheet .summary-sheet-main-table .company-logo {
    max-height: 88px;
    max-width: 100%; }
  .summary-sheet .summary-sheet-main-table .loan-selection .printable {
    border: thin solid #000000; }
  .summary-sheet .summary-sheet-main-table .loan-selection .printable,
  .summary-sheet .summary-sheet-main-table .loan-selection .input-field-container .input-field {
    width: 160px;
    margin-left: 15px;
    display: inline-block;
    text-align: center; }
  .summary-sheet .summary-sheet-main-table colgroup col {
    width: 12.5%; }
  .summary-sheet .summary-sheet-main-table .loan-summaries td:nth-last-child(-n + 5) {
    text-align: right; }
  .summary-sheet .summary-sheet-main-table .loan-summaries-align-center td:nth-last-child(-n + 5) {
    text-align: center; }
  .summary-sheet .summary-sheet-main-table .loan-summaries td:first-child,
  .summary-sheet .summary-sheet-main-table .loan-summaries-align-center td:first-child {
    text-align: left; }
  .summary-sheet .summary-sheet-main-table input,
  .summary-sheet .summary-sheet-main-table select,
  .summary-sheet .summary-sheet-main-table select option {
    background-color: #d9d9d9;
    height: 22px;
    outline: none;
    text-align-last: center;
    text-align: center;
    -ms-text-align-last: center;
    -moz-text-align-last: center; }
  .summary-sheet .summary-sheet-main-table .anti-steering-select select {
    border: none;
    width: 100%; }

/* Printing */
.print-only,
.print-only-flex {
  display: none !important; }

@media print {
  @page {
    .summary-standard-template {
      size: A4; } }
  .summary-standard-template .print-only {
    display: block !important; }
  .summary-standard-template .print-only-flex {
    display: -webkit-flex !important;
    display: flex !important; }
  .summary-standard-template .no-print {
    display: none !important; }
  .summary-standard-template .standard-template-content .sitenav,
  .summary-standard-template .standard-template-content .loan-tool-controls,
  .summary-standard-template .standard-template-content .loan-tool-navigation,
  .summary-standard-template .standard-template-content .scroll-to-top,
  .summary-standard-template .standard-template-content .training-tip-question-mark {
    display: none; }
  .summary-standard-template .standard-template-content .summary-container {
    margin: 0; }
    .summary-standard-template .standard-template-content .summary-container .summary-tab {
      display: none; }
    .summary-standard-template .standard-template-content .summary-container .summary-sheet {
      margin: 0 auto; }
    .summary-standard-template .standard-template-content .summary-container .summary-header,
    .summary-standard-template .standard-template-content .summary-container .light-olive-bg,
    .summary-standard-template .standard-template-content .summary-container .gray-bg {
      -webkit-print-color-adjust: exact;
              color-adjust: exact; }
  /* Firefox only */
  @-moz-document url-prefix() {
    .summary-standard-template .summary-container .summary-header {
      filter: invert(1);
      color: #000000;
      background-color: #ffffff; } } }

.trid {
  font-size: 12px;
  margin: 20px 0;
  width: 100%;
  line-height: 20px; }

.trid .trid-page {
  width: 960px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin: 0 auto 20px auto; }
  .trid .trid-page:not(:last-child) {
    page-break-after: always; }

.trid .trid-page .trid-content {
  -webkit-flex-grow: 1;
          flex-grow: 1;
  padding-bottom: 20px; }

.trid table td {
  height: 20px;
  line-height: 20px; }

.trid .left-right-container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between; }

.trid .left-right-container .left {
  width: 45%; }

.trid .left-right-container .right {
  width: 55%; }

.trid .info-table {
  border-collapse: collapse;
  border-spacing: 0; }

.trid .info-table colgroup col {
  width: 9.6%; }

.trid .info-table colgroup col:first-child {
  width: 23.2%; }

@media print {
  .trid {
    margin: 0 2px; }
  .trid .trid-page {
    height: 1250px;
    margin: 0 auto; } }

.loan-estimate {
  padding-bottom: 20px;
}

.loan-estimate .loan-label {
  float: right;
  margin: 0 0 20px 30px;
}

.loan-estimate .left-right-container {
  padding-top: 10px;
}

.loan-estimate .left-right-container .left,
.loan-estimate .left-right-container .right {
  padding: 0 20px 0 2px;
}

.loan-estimate table {
  width: 100%;
}

.loan-estimate .general-info colgroup col {
  width: 33%;
}

.loan-estimate .loan-info colgroup col {
  width: 14%;
}

.loan-estimate .loan-info colgroup col:first-child {
  width: 16%;
}

.projected-payments .consumer-finance-info {
  text-align: center;
  padding-top: 30px;
}

.page-footer {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  font-size: 11px;
  line-height: 18px;
}

.page-footer .footer-info > span {
  display: inline-block;
  min-width: 70px;
}

.closing-cost-details .mono-font {
  font-size: 11px; }

.closing-cost-details .closing-cost-details-title {
  margin-bottom: 20px; }

.closing-cost-details .left-table col {
  width: 16%; }

.closing-cost-details .left-table col:first-child {
  width: 52%; }

.closing-cost-details .right-table col {
  width: 14%; }

.closing-cost-details .right-table col:first-child {
  width: 16%; }

.closing-cost-details .left-right-container .left,
.closing-cost-details .left-right-container .right {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column; }

.closing-cost-details .loan-costs-content {
  -webkit-flex-grow: 1;
          flex-grow: 1;
  border-width: thin 0 thin thin;
  border-style: solid;
  border-color: #000000; }

.closing-cost-details .other-costs-content {
  -webkit-flex-grow: 1;
          flex-grow: 1;
  border-width: thin thin 0 0;
  border-style: solid;
  border-color: #000000; }

.closing-cost-details .calculating-cash-to-close {
  border-width: 0 thin thin 0;
  border-style: solid;
  border-color: #000000; }

.closing-cost-details .services-by {
  padding: 15px 0;
  width: 25%; }

.closing-cost-details .arm-specifics {
  line-height: 18px; }

.closing-cost-details .arm-specifics table td {
  line-height: 18px;
  height: 18px; }

.closing-cost-details .arm-note {
  margin-right: 10%; }

.closing-cost-details .arm-note > div:first-child {
  margin-right: 15px; }

.adjustable-interest-rate-table colgroup col:first-child {
  width: 50%;
}

.adjustable-interest-rate-table colgroup col:nth-child(2) {
  width: 35%;
}

.adjustable-interest-rate-table colgroup col:last-child {
  width: 15%;
}

table.adjustable-payment-table {
  width: 50%;
}

.adjustable-payment-table .summary-header {
  font-size: 14px;
}

.adjustable-payment-table colgroup col {
  width: 50%;
}

.additional-information .content-section {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  margin-bottom: 16px;
}

.additional-information .content-section .content-title,
.additional-information .content-section .content-value,
.additional-information .content-section .content-description {
  padding: 0 2px;
}

.additional-information .content-section .content-title {
  font-weight: bold;
  -webkit-flex-basis: 290px;
          flex-basis: 290px;
}

.additional-information .content-section .content-value {
  text-align: right;
  -webkit-flex-basis: 70px;
          flex-basis: 70px;
}

.additional-information .content-section .content-description {
  -webkit-flex-grow: 1;
          flex-grow: 1;
}

.lender-broker-information {
  display: -webkit-flex;
  display: flex;
  padding: 20px 0 20px 0;
}

.lender-broker-information .lender-information {
  width: 40%;
}

.lender-broker-information .broker-information {
  width: 60%;
}

.lender-broker-information .content-section {
  margin-bottom: 0;
}

.lender-broker-information .content-section .content-title {
  font-weight: normal;
  -webkit-flex-basis: 33%;
          flex-basis: 33%;
}

.other-considerations .content-section:not(:first-of-type) .content-title {
  -webkit-flex-basis: 150px;
          flex-basis: 150px;
}

.other-considerations .content-section .selections {
  line-height: 16px;
  margin-right: 2px;
}

.other-considerations .content-section .selections select,
.other-considerations .content-section .selections select option {
  background-color: #eef3f8;
  outline: none;
  text-align-last: center;
  text-align: center;
  -ms-text-align-last: center;
  -moz-text-align-last: center;
}

.other-considerations .content-section .selections select {
  border: thin solid #000000;
  height: 22px;
  width: 75px;
}

.other-considerations .content-section .selections > div:first-child select {
  border-bottom: none;
}

@media print {
  .other-considerations .content-section .selections {
    -webkit-print-color-adjust: exact;
            color-adjust: exact;
    background-color: #eef3f8;
    border: thin solid #000000;
  }

  .other-considerations .content-section .selections > div {
    height: 20px;
    width: 75px;
    text-align: center;
    line-height: 20px;
  }

  .other-considerations .content-section .selections > div:first-child {
    border-bottom: thin solid #000000;
  }
}

.confirm-receipt {
  padding-bottom: 20px; }
  .confirm-receipt .receipt-content {
    margin-bottom: 10px;
    padding: 0 2px; }
  .confirm-receipt .signature-container {
    width: 90%; }
    .confirm-receipt .signature-container .signature-line {
      margin-top: 40px; }
    .confirm-receipt .signature-container .signature-block {
      padding: 0 2px; }

.total-cost-analysis {
  font-size: 14px;
  margin: 20px auto;
  width: 960px; }
  .total-cost-analysis .chart-container {
    border: medium solid #ff0000; }
    .total-cost-analysis .chart-container .title {
      padding: 0 2px; }
    .total-cost-analysis .chart-container > div:first-child {
      -webkit-flex-basis: 67%;
              flex-basis: 67%; }
    .total-cost-analysis .chart-container > div:last-child {
      -webkit-flex-basis: 33%;
              flex-basis: 33%; }
    .total-cost-analysis .chart-container .analysis-table {
      margin-top: 20px; }
      .total-cost-analysis .chart-container .analysis-table colgroup col {
        width: 12.5%; }
    .total-cost-analysis .chart-container .bar-container {
      position: relative;
      display: inline-block;
      width: 80%;
      margin-top: 2px; }
      .total-cost-analysis .chart-container .bar-container .bar {
        box-sizing: content-box;
        border: thin solid #638ec6;
        position: absolute;
        top: 0;
        left: 0;
        height: 20px;
        background-image: linear-gradient(to right, #c4d79b, #ffffff); }
      .total-cost-analysis .chart-container .bar-container .bar-content {
        position: relative;
        text-align: right;
        padding: 0 2px; }

@media print {
  .total-cost-analysis {
    height: 1250px;
    margin-bottom: 0; }
    .total-cost-analysis .chart-container .bar-container {
      -webkit-print-color-adjust: exact;
              color-adjust: exact; } }

.refinance-total-cost-analysis {
  border: medium solid #ff0000; }

.password-reset .password-reset-title {
  margin-top: 20px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  font-weight: bold;
  font-size: 20px; }

.password-reset .password-reset-content {
  margin: 10px auto; }
  .password-reset .password-reset-content .password-reset-action {
    width: 400px; }
    .password-reset .password-reset-content .password-reset-action .input-field-container {
      margin-bottom: 3px; }
      .password-reset .password-reset-content .password-reset-action .input-field-container .input-field {
        width: 70%; }
  .password-reset .password-reset-content .error-message {
    color: #ff0000;
    height: 22px;
    min-width: 22px; }

.account-profile .loan-officer-info .input-field-container .input-field {
  margin-bottom: 3px;
  width: 50%; }
  .account-profile .loan-officer-info .input-field-container .input-field.user-role {
    width: 100%; }

.account-profile .loan-officer-info .user-role {
  width: 50%; }
  .account-profile .loan-officer-info .user-role .input-field-container .input-field {
    width: 100%; }

.account-profile .loan-officer-info .company-logo {
  width: 300px;
  text-align: center; }

.account-profile .loan-officer-info .button {
  margin-top: 10px; }

.company-admin-panel .top-header-section {
  position: relative; }
  .company-admin-panel .top-header-section .subscription-panel {
    display: inline-block;
    position: absolute;
    right: 10px;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%); }

.company-admin-panel .company-info .input-field-container {
  margin-bottom: 3px; }
  .company-admin-panel .company-info .input-field-container .input-field {
    width: 50%; }

.company-admin-panel .company-info .button {
  margin-top: 10px; }

.company-admin-panel .user-management .button {
  margin-right: 3px; }

.company-admin-panel .user-management table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px; }
  .company-admin-panel .user-management table th {
    font-weight: bold;
    text-align: left; }
  .company-admin-panel .user-management table td {
    padding: 3px;
    text-align: left;
    border: 1px solid #006296; }
  .company-admin-panel .user-management table .subaccount .subaccount-arrow {
    margin: 0 10px; }

.company-admin-panel .user-management .user-role {
  display: inline-block;
  margin-left: 5px;
  color: #17375e;
  font-weight: bold;
  background-color: #d9d9d9;
  padding: 2px 6px; }

.company-admin-panel .edit-user .button {
  margin-right: 3px; }

.company-admin-panel .edit-user .input-field-container {
  margin-bottom: 3px; }
  .company-admin-panel .edit-user .input-field-container .input-field {
    width: 50%; }

.company-admin-panel .unsubscribe .button {
  margin-left: 3px; }

.button {
  display: inline-block;
  color: #ffffff;
  background-color: #006296;
  padding: 0 12px;
  outline: none;
  border: none;
  border-radius: 5px;
  font-size: 12px;
  cursor: pointer;
  height: 22px;
  line-height: 22px;
  text-decoration: none; }
  .button:disabled {
    background-color: #969696; }
  .button.inverse-button {
    color: #006296;
    border: 2px solid #006296;
    background-color: #ffffff;
    line-height: 18px;
    font-weight: bold; }
  .button.admin-button {
    color: #17375e;
    border: 2px solid #17375e;
    background-color: #ffffff;
    line-height: 18px;
    font-weight: bold; }
    .button.admin-button.active {
      background-color: #17375e;
      color: #ffffff; }

.input-field-container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between; }
  .input-field-container label {
    min-height: 22px;
    line-height: 22px; }
  .input-field-container .required-asterisk {
    color: red; }

.mono-font {
  font-family: 'PT Mono', monospace; }

.half-width {
  width: 50%;
  margin-right: 3%; }

.third-width {
  width: 33%;
  margin-right: 2%; }

.forth-width {
  width: 25%;
  margin-right: 1%; }

.fifth-width {
  width: 20%;
  margin-right: 1%; }

.flex {
  display: -webkit-flex;
  display: flex; }

.flex-start-with-front-spacing,
.flex-start-with-end-spacing {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
          justify-content: flex-start; }

.flex-start-with-front-spacing > * {
  margin-left: auto; }

.flex-start-with-end-spacing > * {
  margin-right: auto; }

.align-items-center {
  -webkit-align-items: center;
          align-items: center; }

.flex-reverse-direction {
  -webkit-flex-direction: row-reverse;
          flex-direction: row-reverse; }

.flex-direction-column {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column; }

.flex-space-between {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between; }

.flex-space-evenly {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly; }

.flex-grow {
  -webkit-flex-grow: 1;
          flex-grow: 1; }

.justify-content-center {
  -webkit-justify-content: center;
          justify-content: center; }

.flex-end {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end; }

.left-align {
  text-align: left !important; }

.right-align {
  text-align: right !important; }

.center-align {
  text-align: center !important; }

.bold {
  font-weight: bold; }

.italic {
  font-style: italic; }

.pointer {
  cursor: pointer; }

.divider {
  height: 5px;
  border-bottom: 1px solid #969696; }

.gray-bg {
  background-color: #d9d9d9; }

.green-text {
  color: #006600; }

.red-text {
  color: #ff0000; }

.error-messages {
  padding: 15px 0;
  color: #ff0000; }

.top-header-section {
  height: 32px;
  display: -webkit-flex;
  display: flex;
  justify-items: flex-start;
  -webkit-align-items: center;
          align-items: center;
  background-color: #d9d9d9;
  padding: 0 20px; }
  .top-header-section .top-header-section-title {
    color: #6e6e6e;
    font-size: 15px;
    font-weight: bold;
    margin-right: 20px; }
  .top-header-section .button {
    margin-right: 3px; }
  .top-header-section .fnm-help-link {
    margin-left: 5px;
    text-decoration: none; }

.code-block {
  font-family: 'PT Mono', monospace;
  display: block;
  padding: 10px;
  margin: 10px 0;
  font-size: 12px;
  color: #000000;
  background-color: #f7f7f7; }

.subscribe-page .main-title {
  font-size: 26px;
  color: #006296;
  text-align: center;
  margin: 5px 0; }

.subscribe-page .content {
  margin-top: 20px; }

.checkout .title {
  font-size: 14px;
  font-weight: bold;
  margin: 20px 0; }

.checkout .plan-title {
  font-weight: bold;
  margin-bottom: 10px; }

.checkout .plan-price {
  margin-bottom: 8px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
          align-items: flex-start; }
  .checkout .plan-price input {
    margin-right: 10px; }


.company-management .input-field-container {
  margin-bottom: 3px; }
  .company-management .input-field-container .input-field {
    width: 50%; }

.company-management .button {
  margin: 3px 3px 0 0; }
  .company-management .button.back-button {
    margin-top: 20px; }

.company-management ul {
  padding-left: 30px;
  margin-bottom: 20px;
  list-style-type: circle; }
  .company-management ul li {
    margin-bottom: 3px; }

.company-management .companies table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px; }
  .company-management .companies table th {
    font-weight: bold; }
  .company-management .companies table td {
    text-align: center;
    border: 1px solid #006296; }
    .company-management .companies table td:last-child {
      border: none; }

.company-management .add-multiple-users .input-field-container .input-field {
  width: 80%; }

.checkbox-field-container .checkbox-field {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }
  .checkbox-field-container .checkbox-field input {
    margin: 0; }

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  font-size: 13px;
  color: #000000;
  background-color: #ffffff; }

* {
  box-sizing: border-box; }

.button {
  display: inline-block;
  color: #ffffff;
  background-color: #006296;
  padding: 0 12px;
  outline: none;
  border: none;
  border-radius: 5px;
  font-size: 12px;
  cursor: pointer;
  height: 22px;
  line-height: 22px;
  text-decoration: none; }
  .button:disabled {
    background-color: #969696; }
  .button.inverse-button {
    color: #006296;
    border: 2px solid #006296;
    background-color: #ffffff;
    line-height: 18px;
    font-weight: bold; }
  .button.admin-button {
    color: #17375e;
    border: 2px solid #17375e;
    background-color: #ffffff;
    line-height: 18px;
    font-weight: bold; }
    .button.admin-button.active {
      background-color: #17375e;
      color: #ffffff; }

.input-field-container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between; }
  .input-field-container label {
    min-height: 22px;
    line-height: 22px; }
  .input-field-container .required-asterisk {
    color: red; }

.mono-font {
  font-family: 'PT Mono', monospace; }

.half-width {
  width: 50%;
  margin-right: 3%; }

.third-width {
  width: 33%;
  margin-right: 2%; }

.forth-width {
  width: 25%;
  margin-right: 1%; }

.fifth-width {
  width: 20%;
  margin-right: 1%; }

.flex {
  display: -webkit-flex;
  display: flex; }

.flex-start-with-front-spacing,
.flex-start-with-end-spacing {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
          justify-content: flex-start; }

.flex-start-with-front-spacing > * {
  margin-left: auto; }

.flex-start-with-end-spacing > * {
  margin-right: auto; }

.align-items-center {
  -webkit-align-items: center;
          align-items: center; }

.flex-reverse-direction {
  -webkit-flex-direction: row-reverse;
          flex-direction: row-reverse; }

.flex-direction-column {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column; }

.flex-space-between {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between; }

.flex-space-evenly {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly; }

.flex-grow {
  -webkit-flex-grow: 1;
          flex-grow: 1; }

.justify-content-center {
  -webkit-justify-content: center;
          justify-content: center; }

.flex-end {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end; }

.left-align {
  text-align: left !important; }

.right-align {
  text-align: right !important; }

.center-align {
  text-align: center !important; }

.bold {
  font-weight: bold; }

.italic {
  font-style: italic; }

.pointer {
  cursor: pointer; }

.divider {
  height: 5px;
  border-bottom: 1px solid #969696; }

.gray-bg {
  background-color: #d9d9d9; }

.green-text {
  color: #006600; }

.red-text {
  color: #ff0000; }

.error-messages {
  padding: 15px 0;
  color: #ff0000; }

.top-header-section {
  height: 32px;
  display: -webkit-flex;
  display: flex;
  justify-items: flex-start;
  -webkit-align-items: center;
          align-items: center;
  background-color: #d9d9d9;
  padding: 0 20px; }
  .top-header-section .top-header-section-title {
    color: #6e6e6e;
    font-size: 15px;
    font-weight: bold;
    margin-right: 20px; }
  .top-header-section .button {
    margin-right: 3px; }
  .top-header-section .fnm-help-link {
    margin-left: 5px;
    text-decoration: none; }

.code-block {
  font-family: 'PT Mono', monospace;
  display: block;
  padding: 10px;
  margin: 10px 0;
  font-size: 12px;
  color: #000000;
  background-color: #f7f7f7; }


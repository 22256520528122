@import '../../../css/colors';
@import '../../../css/mixins';

.time-field-container {
  .time-field {
    @include input;
    display: inline-block;
    text-align: right;
    padding: 0;
    border: none;

    .react-datepicker-wrapper {
      width: 100%;

      .time-field-input {
        @include input;
        width: 100%;
        text-align: right;
      }
    }

    .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
      background-color: $blue;

      &:hover {
        background-color: $darkBlue;
      }
    }
  }
}

@import '../../../css/colors';

.down-payment {
  padding-top: 10px;

  .input-field-container {
    margin-bottom: 3px;
    font-size: 12px;

    .input-field {
      font-size: 12px;
    }
  }

  .button {
    margin-bottom: 3px;
  }

  .down-payment-section-title {
    font-weight: bold;
    margin: 10px 0;
  }

  .down-payment-all-loans-the-same {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    > div {
      margin-right: 10px;
    }
  }

  .down-payment-all-loans-same-emd-seller-concessions {
    margin-bottom: 10px;

    > div {
      margin-bottom: 5px;
    }
  }

  .configure-dti {
    display: flex;
    justify-content: space-between;
    line-height: 22px;

    .button {
      margin-left: 10px;
    }
  }

  .down-payment-top {
    .input-field {
      width: 50%;
    }
  }

  .down-payment-edit-modal {
    width: 50%;

    .input-field {
      width: 40%;
    }

    .button {
      margin: 10px 3px 10px 0;
    }
  }

  .down-payment-calculation-result {
    .input-field-container {
      .input-field {
        &.red-outlined {
          color: $red;
          background-color: $white;
          border: 1px solid $red;
        }
        &.yellow-highlighted {
          background-color: $yellow;
        }
      }
    }
  }

  .loan-calculator-button {
    margin: 10px 0;
  }

  .refinance-loan-calculator {
    .input-field-container {
      width: 50%;

      .input-field {
        width: 40%;
      }
    }

    .button {
      margin: 10px 3px 10px 0;
    }
  }
}
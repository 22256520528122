.applicant-info {
  padding-top: 10px;

  .applicant-info-table {
    table {
      width: 100%;

      th {
        text-align: left;
      }

      td {
        text-align: center;
        padding: 0;
        margin: 0;
      }

      .text-field, .phone-field {
        flex-grow: 1;
      }
    }

    .add-borrower-button {
      margin-top: 5px;
    }
  }

  .edit-borrower {
    .input-field-container {
      margin-bottom: 3px;

      .input-field {
        width: 50%;
      }
    }

    .button {
      margin-right: 3px;
      margin-bottom: 3px;
    }
  }
}

@import '../../../css/colors';

.company-management {
  .input-field-container {
    margin-bottom: 3px;

    .input-field {
      width: 50%;
    }
  }

  .button {
    margin: 3px 3px 0 0;

    &.back-button {
      margin-top: 20px;
    }
  }

  ul {
    padding-left: 30px;
    margin-bottom: 20px;
    list-style-type: circle;

    li {
      margin-bottom: 3px;
    }
  }

  .companies {
    table {
      width: 100%;
      border-collapse: collapse;
      margin-top: 10px;

      th {
        font-weight: bold;
      }

      td {
        text-align: center;
        border: 1px solid $blue;

        &:last-child {
          border: none;
        }
      }
    }
  }

  .add-multiple-users {
    .input-field-container {
      .input-field {
        width: 80%;
      }
    }
  }
}

table.adjustable-payment-table {
  width: 50%;
}

.adjustable-payment-table .summary-header {
  font-size: 14px;
}

.adjustable-payment-table colgroup col {
  width: 50%;
}

.adjustable-interest-rate-table colgroup col:first-child {
  width: 50%;
}

.adjustable-interest-rate-table colgroup col:nth-child(2) {
  width: 35%;
}

.adjustable-interest-rate-table colgroup col:last-child {
  width: 15%;
}

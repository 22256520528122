@import '../../../css/colors';
@import '../../../css/mixins';

.loan-picker-navigation {
  display: flex;
  position: relative;
  align-items: center;
  font-size: 12px;
  margin-top: 20px;
  margin-left: 110px;
  margin-bottom: 10px;

  .button {
    height: 24px;
  }

  .loan-tab {
    position: relative;
    border: 2px solid $gray;
    height: 24px;
    line-height: 22px;
    border-radius: 5px;
    width: 100px;
    cursor: pointer;
    text-align: center;
    color: $darkerGray;

    &:after {
      position: absolute;
      content: " ";
      width: 16px;
      height: 16px;
      border-radius: 8px;
      background-color: $gray;
      right: 4px;
      top: 50%;
      transform: translateY(-50%);
    }

    &.active {
      color: $darkBlue;
      border-color: $blue;

      &:after {
        background-color: $blue;
      }
    }

    &.marked {
      &:after {
        background-color: $green;
      }
    }

    &:not(:first-child) {
      margin-left: 23px;

      .nav-arrow {
        @include navArrow;
        display: none;
        position: absolute;
        top: -2px;
        left: -25px;
        pointer-events: none;
      }

      .nav-connector {
        position: absolute;
        left: -25px;
        top: 2px;
        width: 23px;
        height: 16px;
        line-height: 24px;
        background-color: $gray;
        pointer-events: none;
      }

      &.active {
        .nav-arrow {
          display: flex;
        }

        .nav-connector {
          display: none;
        }
      }

      &.marked {
        .nav-arrow {
          display: flex;
        }

        .nav-connector {
          display: none;
        }
      }
    }
  }

  .loan-tab-shell {
    position: relative;
    margin-left: 23px;

    .nav-connector {
      position: absolute;
      left: -23px;
      top: 4px;
      width: 23px;
      height: 16px;
      line-height: 24px;
      background-color: $gray;
      pointer-events: none;
    }
  }
}

@import '../../../css/colors';
@import '../../../css/mixins';

.date-field-container {
  .date-field {
    @include input;
    display: inline-block;
    text-align: right;
    padding: 0;
    border: none;

    .react-datepicker-wrapper {
      width: 100%;

      .date-field-input {
        @include input;
        width: 100%;
        text-align: right;
      }
    }

    .react-datepicker__day--selected,
    .react-datepicker__day--keyboard-selected {
      background-color: $blue;

      &:hover {
        background-color: $darkBlue;
      }
    }
  }
}

.additional-information .content-section {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 16px;
}

.additional-information .content-section .content-title,
.additional-information .content-section .content-value,
.additional-information .content-section .content-description {
  padding: 0 2px;
}

.additional-information .content-section .content-title {
  font-weight: bold;
  flex-basis: 290px;
}

.additional-information .content-section .content-value {
  text-align: right;
  flex-basis: 70px;
}

.additional-information .content-section .content-description {
  flex-grow: 1;
}

.closing-cost-details {
  .mono-font {
    font-size: 11px;
  }
}

.closing-cost-details .closing-cost-details-title {
  margin-bottom: 20px;
}

.closing-cost-details .left-table col {
  width: 16%;
}

.closing-cost-details .left-table col:first-child {
  width: 52%;
}

.closing-cost-details .right-table col {
  width: 14%;
}

.closing-cost-details .right-table col:first-child {
  width: 16%;
}

.closing-cost-details .left-right-container .left,
.closing-cost-details .left-right-container .right {
  display: flex;
  flex-direction: column;
}

.closing-cost-details .loan-costs-content {
  flex-grow: 1;
  border-width: thin 0 thin thin;
  border-style: solid;
  border-color: #000000;
}
.closing-cost-details .other-costs-content {
  flex-grow: 1;
  border-width: thin thin 0 0;
  border-style: solid;
  border-color: #000000;
}

.closing-cost-details .calculating-cash-to-close {
  border-width: 0 thin thin 0;
  border-style: solid;
  border-color: #000000;
}

.closing-cost-details .services-by {
  padding: 15px 0;
  width: 25%;
}

.closing-cost-details .arm-specifics {
  line-height: 18px;
}

.closing-cost-details .arm-specifics table td {
  line-height: 18px;
  height: 18px;
}

.closing-cost-details .arm-note {
  margin-right: 10%;
}

.closing-cost-details .arm-note > div:first-child {
  margin-right: 15px;
}

$white: #ffffff;
$black: #000000;

$lightGray: #f7f7f7;
$gray: #d9d9d9;
$darkGray: #969696;
$darkerGray: #6e6e6e;

$blue: #006296;
$darkBlue: #17375e;

$green: #99cc33;
$darkGreen: #006600;

$yellow: #ffff99;
$darkYellow: #ffbf00;

$red: #ff0000;

$pink: #ffc7ce;

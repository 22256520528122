.trid {
  font-size: 12px;
  margin: 20px 0;
  width: 100%;
  line-height: 20px;
}

.trid .trid-page {
  width: 960px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 auto 20px auto;

  &:not(:last-child) {
    page-break-after: always;
  }
}

.trid .trid-page .trid-content {
  flex-grow: 1;
  padding-bottom: 20px;
}

.trid table td {
  height: 20px;
  line-height: 20px;
}

.trid .left-right-container {
  display: flex;
  justify-content: space-between;
}

.trid .left-right-container .left {
  width: 45%;
}

.trid .left-right-container .right {
  width: 55%;
}

.trid .info-table {
  border-collapse: collapse;
  border-spacing: 0;
}

.trid .info-table colgroup col {
  width: 9.6%;
}

.trid .info-table colgroup col:first-child {
  width: 23.2%;
}

@media print {
  .trid {
    margin: 0 2px;
  }

  .trid .trid-page {
    height: 1250px;
    margin: 0 auto;
  }
}

@import './css/colors';

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  font-size: 13px;
  color: $black;
  background-color: $white;
}

* {
  box-sizing: border-box;
}

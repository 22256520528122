@import '../../../css/colors';

$leftWidth: 446px;
$leftInnerWidth: 380px;
$rightWidth: 578px;

.closing-costs-worksheet {
  font-size: 12px;

  .top-border {
    border-top: thin solid $black;
  }

  .right-border {
    border-right: thin solid $black;
  }

  .bottom-border {
    border-bottom: thin solid $black;

    &.medium-border {
      border-width: medium;
    }
  }

  .mono-font {
    font-size: 11px;
  }

  .input-field-container {
    .input-field {
      font-size: 11px;
    }
  }

  .section-title {
    font-weight: bold;
    font-size: 13px;
    line-height: 23px;
    margin-right: 3px;
  }

  .closing-costs-main {
    margin-top: 20px;

    .button {
      height: 18px;
      line-height: 19px;
      margin: 0 6px;
    }

    .closing-costs-main-top {
      .main-top-title {
        width: 210px;
        background-color: $gray;
        font-size: 15px;
        font-weight: bold;
        height: 22px;
        line-height: 23px;
        padding-left: 3px;
      }

      .main-top-left {
        width: $leftWidth;
        padding-top: 22px;
        border-left: thin solid $black;
      }

      .main-top-right {
        width: $rightWidth;
        padding-top: 22px;
        border-right: thin solid $black;
      }
    }

    .closing-costs-main-section {
      display: flex;

      .loan-fees {
        .button {
          margin: 0 3px 0 0;
          width: 18px;
          border-radius: 9px;
          padding: 0;
        }

        .edit-fee-list {
          .input-field-container {
            flex-grow: 1;
            margin-left: -3px;

            .input-field {
              width: 100%;
            }
          }
        }
      }

      .closing-costs-row {
        height: 22px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 3px;

        &.flex-start {
          justify-content: flex-start;
        }

        &.overflow-hidden {
          overflow: hidden;
        }

        .closing-costs-cell {
          display: inline-block;
          padding: 0 3px;
          height: 22px;
          line-height: 23px;
          width: 82px;
        }

        .closing-costs-cell-shell {
          display: inline-block;
          height: 22px;
          line-height: 23px;
          width: 82px;

          &.double-width {
            width: 164px;
          }

          &.triple-width {
            width: 246px;
          }

          &.quad-width {
            width: 328px;
          }
        }

        .input-field-container {
          .input-field {
            width: 82px;
            padding: 0 3px;
          }
        }
      }

      .closing-costs-main-left {
        display: flex;
        width: $leftWidth;
        border-style: solid;
        border-color: $black;
        border-width: thin 0 thin thin;

        .closing-costs-main-left-inner {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          width: $leftInnerWidth;

          .origination-charges {
            .loan-amount-points {
              text-align: center;
              margin-left: -3px;
            }
          }
        }
      }

      .closing-costs-main-right {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: $rightWidth;
        border-style: solid;
        border-color: $black;
        border-width: thin thin thin 0;

        .calculating-cash-to-close {
          .main-title {
            font-weight: bold;
            font-size: 15px;
            background-color: $black;
            color: $white;
            margin-left: -3px;
            padding-left: 3px;
            height: 22px;
            line-height: 23px;
            width: 210px
          }

          .gray-dashed-border {
            border-width: medium medium 0 medium;
            border-color: $gray;
            border-style: dashed;
          }
        }
      }

      .origination-charges,
      .services-you-cannot-shop-for,
      .services-you-can-shop-for,
      .taxes-and-other-government-fees,
      .prepaids,
      .initial-escrow-payment-at-closing,
      .other,
      .total-other-costs,
      .total-closing-costs {
        margin-bottom: 22px;
      }
    }

    .services-by {
      margin-top: 22px;
      width: $leftInnerWidth;

      .input-field-container {
        margin-bottom: 3px;

        .input-field {
          width: 50%;
        }
      }
    }

    .arm-table {
      display: flex;
      line-height: 22px;
      font-size: 13px;

      .arm-table-left {
        width: $leftWidth;
        display: flex;

        .arm-note-title {
          font-size: 14px;
          font-weight: bold;
          margin-right: 20px;
        }
      }

      .arm-table-right {
        width: $rightWidth;

        .arm-table-row {
          display: flex;

          & > div {
            flex-basis: 100%;
          }
        }
      }
    }

    .ap-table {
      width: 480px;
      line-height: 22px;
      font-size: 13px;

      .ap-table-title {
        font-weight: bold;
        font-size: 15px;
        background-color: $black;
        color: $white;
        padding: 0 3px;
        height: 22px;
        width: 50%;
      }

      .ap-table-content {
        border: thin solid $black;

        .ap-table-row {
          width: 100%;
          display: flex;

          &:not(:first-child) {
            border-top: thin solid $black;
          }

          & > div {
            flex-basis: 100%;
            height: 22px;
            padding: 0 3px;
          }
        }
      }
    }
  }

  .transfer-taxes-calculator,
  .prepaids-calculator {
    font-size: 13px;
    width: 50%;

    .input-field-container {
      margin-bottom: 3px;

      .input-field {
        width: 40%;
        font-size: 13px;
      }
    }

    .button {
      margin: 10px 3px 10px 0;
    }
  }
}

.loan-estimate {
  padding-bottom: 20px;
}

.loan-estimate .loan-label {
  float: right;
  margin: 0 0 20px 30px;
}

.loan-estimate .left-right-container {
  padding-top: 10px;
}

.loan-estimate .left-right-container .left,
.loan-estimate .left-right-container .right {
  padding: 0 20px 0 2px;
}

.loan-estimate table {
  width: 100%;
}

.loan-estimate .general-info colgroup col {
  width: 33%;
}

.loan-estimate .loan-info colgroup col {
  width: 14%;
}

.loan-estimate .loan-info colgroup col:first-child {
  width: 16%;
}

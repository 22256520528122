@import "../../../../css/colors";

.debt-info-account-overview {
  .account-overview-top {
    margin-top: 20px;

    .input-field-container {
      margin-bottom: 3px;
      width: 80%;
    }
  }

  .account-overview-loan-tabs {
    margin-bottom: 10px;
    display: flex;
    justify-content: flex-start;

    .loan-tab {
      margin-right: 10px;
      border-radius: 5px;
      border: 2px solid $darkGray;
      color: $darkGray;
      padding: 0 12px;
      height: 22px;
      line-height: 18px;
      cursor: pointer;

      &.active {
        color: $darkBlue;
        border-color: $blue;
      }
    }
  }

  .account-table {
    .account-table-header {
      position: relative;
      margin-bottom: 20px;

      .credit-score {
        position: absolute;
        right: 0;
        top: 0;
      }
    }

    table {
      width: 100%;
      font-size: 10px;
      table-layout: fixed;

      th {
        &:nth-child(-n+4),
        &:nth-child(12) {
          width: 46px;
        }

        &:last-child {
          width: 74px;
        }

        &:nth-child(14) {
          width: 100px;
        }
      }

      td {
        text-align: center;
      }

      .input-field {
        font-size: 10px;
        width: 100%;
        padding: 0 2px;
      }

      .disposition-field {
        width: 110px;
      }

      .button {
        font-size: 11px;
        padding: 0 5px;
      }
    }

    .loan-dti-section {
      display: flex;
      justify-content: space-around;
      margin: 0 20% 10px 20%;

      .loan-dti {
        text-align: center;
        font-size: 12px;
        font-weight: bold;
        margin-top: 20px;

        & > div {
          display: inline-block;
          margin-right: 20px;
        }
      }
    }
  }
}

@import "../../../css/colors";

.section {
  position: relative;
  border-color: $gray;
  border-style: solid;
  border-width: 2px 2px 0 0;
  margin-top: 30px;
  padding: 20px 10px 5px 0;

  .section-name {
    position: absolute;
    background-color: $white;
    top: 0;
    left: 0;
    font-weight: bold;
    font-size: 17px;
    margin-top: -11px;
    padding-right: 10px;
  }
}

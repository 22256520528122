@import '../../../css/colors';

.stripe-checkout {
  width: 320px;

  .input-field-container {
    margin-bottom: 3px;

    .input-field {
      width: 100%;
    }
  }

  .card-element {
    width: 100%;
    margin-bottom: 3px;
    border: 1px dotted $darkGray;
    height: 22px;
    padding: 0 3px;
    background-color: $lightGray;
  }

  .promo-code {
    margin-bottom: 8px;
  }
}

.checkout {
  .title {
    font-size: 14px;
    font-weight: bold;
    margin: 20px 0;
  }

  .plan-title {
    font-weight: bold;
    margin-bottom: 10px;
  }

  .plan-price {
    margin-bottom: 8px;
    display: flex;
    align-items: flex-start;

    input {
      margin-right: 10px;
    }
  }
}

.sitenav {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 10px;
  margin-top: 5px;
  margin-bottom: 2px;

  .tier-one-logo {
    height: 30px;
  }

  & > .brand {
    flex: 1;
  }

  .header-links {
    & > a {
      margin-left: 10px;
    }
  }
}

.debt-info-add-account {
  padding-top: 10px;

  .divider {
    margin-bottom: 5px;
  }

  .input-field-container {
    margin-bottom: 3px;

    .input-field {
      width: 50%;
    }
  }

  .button {
    margin-top: 10px;
    margin-right: 3px;
  }

  .residence-reserve-calculator {
    .section-title {
      font-weight: bold;
      margin: 10px 0;
    }
  }
}

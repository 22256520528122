.income {
  padding-top: 10px;

  .income-information {
    margin-bottom: 40px;

    table {
      width: 100%;

      th {
        font-size: 13px;
      }

      .input-field {
        width: 100%;
      }
    }

    .button {
      margin-right: 3px;
    }

    .divider {
      margin: 20px 0;
    }
  }

  .edit-borrower-income {
    .edit-borrower-income-top {
      width: 50%;
      margin-bottom: 20px;

      .input-field-container {
        margin-bottom: 3px;

        .input-field {
          width: 50%;
        }
      }
    }

    .edit-borrower-income-calculator {
      margin: 30px 0 20px 0;

      table {
        width: 100%;
        table-layout: fixed;
        margin-bottom: 20px;

        .input-field {
          width: 100%;
        }
      }
    }

    .button {
      margin-right: 3px;
    }
  }

  .income-employment-information {
    .employment-information-fields {
      width: 70%;
      margin-bottom: 10px;

      .input-field-container {
        margin-bottom: 3px;

        .input-field {
          width: 50%;
        }
      }
    }

    .button {
      margin-right: 3px;
    }
  }

  .edit-additional-income {
    .additional-income-fields {
      width: 70%;
      margin-bottom: 10px;

      .input-field-container {
        margin-bottom: 3px;

        .input-field {
          width: 50%;
        }
      }
    }

    .social-security-header {
      margin: 20px 0 10px 0;
      font-weight: bold;
    }

    .button {
      margin-right: 3px;
    }
  }

  .income-summary {
    .input-field-container {
      margin-bottom: 3px;
    }
  }
}

.lender-broker-information {
  display: flex;
  padding: 20px 0 20px 0;
}

.lender-broker-information .lender-information {
  width: 40%;
}

.lender-broker-information .broker-information {
  width: 60%;
}

.lender-broker-information .content-section {
  margin-bottom: 0;
}

.lender-broker-information .content-section .content-title {
  font-weight: normal;
  flex-basis: 33%;
}

.selling-property {
  padding-top: 20px;

  .input-field-container {
    margin-bottom: 3px;
  }

  .selling-property-section {
    .input-field-container {
      .input-field {
        width: 33%;
      }
    }
  }

  .important-questions-to-ask {
    .input-field-container {
      .input-field {
        width: 33%;
      }
    }
  }
}

.total-cost-analysis {
  font-size: 14px;
  margin: 20px auto;
  width: 960px;

  .chart-container {
    border: medium solid #ff0000;

    .title {
      padding: 0 2px;
    }

    > div:first-child {
      flex-basis: 67%;
    }

    > div:last-child {
      flex-basis: 33%;
    }

    .analysis-table {
      margin-top: 20px;

      colgroup col {
        width: 12.5%;
      }
    }

    .bar-container {
      position: relative;
      display: inline-block;
      width: 80%;
      margin-top: 2px;

      .bar {
        box-sizing: content-box;
        border: thin solid #638ec6;
        position: absolute;
        top: 0;
        left: 0;
        height: 20px;
        background-image: linear-gradient(to right, #c4d79b, #ffffff);
      }

      .bar-content {
        position: relative;
        text-align: right;
        padding: 0 2px;
      }
    }
  }
}

@media print {
  .total-cost-analysis {
    height: 1250px;
    margin-bottom: 0;

    .chart-container {
      .bar-container {
        color-adjust: exact;
      }
    }
  }
}

@import '../../../css/colors';

.standard-template {
  position: relative;
  width: 1024px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 20px;

  .standard-template-content {
    position: relative;
    width: 100%;

    .scroll-to-top {
      position: fixed;
      bottom: 6px;
      right: 50%;
      transform: translateX(512px);
      margin: 10px 12px 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      border: 1px solid $blue;
      border-radius: 5px;
      padding-top: 2px;
      width: 40px;
      cursor: pointer;

      @media (max-width: 1024px) {
        right: 0;
        transform: none;
      }

      .arrow-head {
        border-bottom: 16px $blue solid;
        border-left: 18px transparent solid;
        border-right: 18px transparent solid;
        width: 0;
        height: 0;
      }
    }
  }
}

@import '../../css/colors';

.password-reset {
  .password-reset-title {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    font-weight: bold;
    font-size: 20px;
  }

  .password-reset-content {
    margin: 10px auto;

    .password-reset-action {
      width: 400px;

      .input-field-container {
        margin-bottom: 3px;

        .input-field {
          width: 70%;
        }
      }
    }

    .error-message {
      color: $red;
      height: 22px;
      min-width: 22px;
    }
  }
}

@import '../../../css/colors';

:root {
  --reach-tooltip: 1;
}

[data-reach-tooltip] {
  z-index: 1;
  pointer-events: none;
  position: absolute;
  padding: 5px 8px;
  box-shadow: 2px 2px 10px hsla(0, 0, 0, 0.6);
  font-size: 12px;
  line-height: 16px;
  background: $black;
  color: $white;
  border: none;
  border-radius: 4px;
  max-width: 330px;
}

.training-tip-question-mark {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  vertical-align: baseline;
  width: 14px;
  height: 14px;
  border-radius: 7px;
  border: none;
  font-size: 11px;
  text-decoration: none;
  font-style: normal;
  font-weight: normal;
  cursor: pointer;
  background-color: $blue;
  color: $white;
}

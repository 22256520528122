@import 'colors';

@mixin inputBasic {
  border: 1px dotted $darkGray;
  font-family: 'Roboto', sans-serif;
  font-size: 13px;
  padding: 0 5px;
  height: 22px;
  width: 140px;
  flex-shrink: 0;

  // IE11
  &:not(select)[readonly] {
    border: none;
  }
  &[readonly] {
    outline: none;
  }

  &:not(select):read-only {
    border: none;
  }

  &:read-only {
    outline: none;
  }
}

@mixin input {
  @include inputBasic;
  background-color: $lightGray;

  // IE11
  &[readonly] {
    background-color: $gray;
  }

  &:read-only {
    background-color: $gray;
  }
}

@mixin monoFont {
  font-family: 'PT Mono', monospace;
}

@mixin inputContainer {
  display: flex;
  justify-content: space-between;

  label {
    min-height: 22px;
    line-height: 22px;
  }

  .required-asterisk {
    color: red;
  }
}

@mixin navArrow {
  display: flex;
  align-items: center;
  margin: 0 1px 0 2px;

  .arrow-body {
    width: 8px;
    height: 12px;
    background-color: $gray;
  }

  .arrow-head {
    border-left: 12px $gray solid;
    border-top: 12px transparent solid;
    border-bottom: 12px transparent solid;
    width: 0;
    height: 0;
  }
}

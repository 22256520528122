.confirm-receipt {
  padding-bottom: 20px;

  .receipt-content {
    margin-bottom: 10px;
    padding: 0 2px;
  }

  .signature-container {
    width: 90%;

    .signature-line {
      margin-top: 40px;
    }

    .signature-block {
      padding: 0 2px;
    }
  }
}

@import '../../../css/mixins';

.percent-field-container {
  .percent-field {
    @include input;
    @include monoFont;
    text-align: right;
    transition: all 0.3s ease-in-out;
  }
}

.page-footer {
  display: flex;
  justify-content: space-between;
  font-size: 11px;
  line-height: 18px;
}

.page-footer .footer-info > span {
  display: inline-block;
  min-width: 70px;
}

@import '../../../css/colors';

.summary-container {
  line-height: 22px;
  margin-bottom: 20px;
  margin-top: 20px;
  width: 100%;

  .summary-tab-container {
    margin-bottom: 20px;
    display: flex;
    justify-content: center;

    .summary-tab {
      margin: 0 5px;
      border-radius: 5px;
      border: 2px solid $darkGray;
      color: $darkGray;
      padding: 0 12px;
      height: 22px;
      line-height: 18px;
      cursor: pointer;

      &.active {
        color: $darkBlue;
        border-color: $blue;
      }
    }
  }
}

.summary-container table {
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
  margin: 0;
  table-layout: fixed;
}

.summary-container table td {
  text-align: left;
  vertical-align: top;
  height: 22px;
  line-height: 22px;
  padding: 0 4px;
}

/* Formats */
.summary-container .bold {
  font-weight: bold;
}

.summary-container .italic {
  font-style: italic;
}

.summary-container .underline {
  text-decoration: underline;
}

.summary-container .normal-font {
  font-size: 13px;
}

.summary-container .big-font {
  font-size: 16px;
}

.summary-container .bigger-font {
  font-size: 18px;
}

.summary-container .biggest-font {
  font-size: 20px;
}

.summary-container .small-font {
  font-size: 12px;
}

.summary-container .smaller-font {
  font-size: 11px;
}

.summary-container .smallest-font {
  font-size: 10px;
}

.summary-container .green-text {
  color: #006600;
}

.summary-container .blue-text {
  color: #0000ff;
}

.summary-container .light-olive-bg {
  background-color: #ebf1de;
}

.summary-container .gray-bg {
  background-color: #d9d9d9;
}

.summary-container .flex-space-between {
  display: flex;
  justify-content: space-between;
}

.summary-container .flex-column-space-between {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.summary-container .flex-start {
  display: flex;
  justify-content: flex-start;
}

.summary-container .flex-start-with-spacing {
  display: flex;
  justify-content: flex-start;
}

.summary-container .flex-start-with-spacing > div {
  margin-right: auto;
}

/* Borders - Order matters */
/* Using keywords (i.e. thin) due to chrome rendering issue */
.summary-container .with-border,
.summary-container .with-td-border td {
  border: thin solid #000000;
}

.summary-container .no-border,
.summary-container tbody td.no-border {
  border: none;
}

.summary-container .top-border,
.summary-container tbody td.top-border {
  border-top: thin solid #000000;
}

.summary-container .right-border,
.summary-container tbody td.right-border {
  border-right: thin solid #000000;
}

.summary-container .bottom-border,
.summary-container tbody td.bottom-border {
  border-bottom: thin solid #000000;
}

.summary-container .left-border,
.summary-container tbody td.left-border {
  border-left: thin solid #000000;
}

.summary-container .double-top-border,
.summary-container tbody td.double-top-border {
  border-top: medium double #000000;
}

.summary-container .double-bottom-border,
.summary-container tbody td.double-bottom-border {
  border-bottom: medium double #000000;
}

.summary-container .thick-top-border,
.summary-container tbody td.thick-top-border {
  border-top: medium solid #000000;
}

.summary-container .thick-bottom-border,
.summary-container tbody td.thick-bottom-border {
  border-bottom: medium solid #000000;
}

.summary-container .thick-left-border,
.summary-container tbody td.thick-left-border {
  border-left: medium solid #000000;
}

.summary-container .no-top-border,
.summary-container tbody td.no-top-border {
  border-top: none;
}

.summary-container .no-right-border,
.summary-container tbody td.no-right-border {
  border-right: none;
}

.summary-container .no-bottom-border,
.summary-container tbody td.no-bottom-border {
  border-bottom: none;
}

.summary-container .no-left-border,
.summary-container tbody td.no-left-border {
  border-left: none;
}

/* Alignments */
.summary-container td.center-align {
  text-align: center !important;
}

.summary-container td.left-align {
  text-align: left !important;
}

.summary-container td.right-align {
  text-align: right !important;
}

.summary-container td.middle-align {
  vertical-align: middle;
}

/* Signatures */
.summary-container .signature-container {
  display: flex;
  justify-content: space-between;
  height: 100%;
}

.summary-container .signature-container .signature-line {
  border-bottom: thin solid #000000;
}

.summary-container .signature-container .signature-line,
.summary-container .signature-container .signature-block {
  display: flex;
  width: 45%;
}

.summary-container .signature-container .signature-block > :first-child {
  flex-grow: 3;
}

.summary-container .signature-container .signature-block > :not(:first-child) {
  flex-grow: 1;
}

.summary-container .summary-header {
  font-weight: bold;
  font-size: 16px;
  color: #ffffff;
  background-color: #000000;
}

.summary-container td .indent {
  padding-left: 1em;
}

/* Summary sheet specifics */
.summary-sheet {
  font-size: 13px;
  margin: 15px auto 25px auto;
  width: 960px;

  .refinance-anti-steering {
    font-size: 12px;
    padding: 0 1px;
    text-align: center;
  }

  .summary-sheet-main-table {
    .company-logo {
      max-height: 88px;
      max-width: 100%;
    }

    .loan-selection {
      .printable {
        border: thin solid $black;
      }

      .printable,
      .input-field-container .input-field {
        width: 160px;
        margin-left: 15px;
        display: inline-block;
        text-align: center;
      }
    }
  }

  .summary-sheet-main-table colgroup col {
    width: 12.5%;
  }

  .summary-sheet-main-table .loan-summaries td:nth-last-child(-n + 5) {
    text-align: right;
  }

  .summary-sheet-main-table .loan-summaries-align-center td:nth-last-child(-n + 5) {
    text-align: center;
  }

  .summary-sheet-main-table .loan-summaries td:first-child,
  .summary-sheet-main-table .loan-summaries-align-center td:first-child {
    text-align: left;
  }

  /* Inputs */
  .summary-sheet-main-table input,
  .summary-sheet-main-table select,
  .summary-sheet-main-table select option {
    background-color: #d9d9d9;
    height: 22px;
    outline: none;
    text-align-last: center;
    text-align: center;
    -ms-text-align-last: center;
    -moz-text-align-last: center;
  }

  .summary-sheet-main-table .anti-steering-select select {
    border: none;
    width: 100%;
  }
}

/* Printing */
.print-only,
.print-only-flex {
  display: none !important;
}

@media print {
  .summary-standard-template {
    @page {
      size: A4;
    }

    .print-only {
      display: block !important;
    }

    .print-only-flex {
      display: flex !important;
    }

    .no-print {
      display: none !important;
    }

    .standard-template-content {
      .sitenav,
      .loan-tool-controls,
      .loan-tool-navigation,
      .scroll-to-top,
      .training-tip-question-mark {
        display: none;
      }

      .summary-container {
        margin: 0;

        .summary-tab {
          display: none;
        }

        .summary-sheet {
          margin: 0 auto;
        }

        .summary-header,
        .light-olive-bg,
        .gray-bg {
          color-adjust: exact;
        }
      }
    }
  }

  /* Firefox only */
  @-moz-document url-prefix() {
    .summary-standard-template .summary-container .summary-header {
      filter: invert(1);
      color: #000000;
      background-color: #ffffff;
    }
  }
}

.le-price-engine-options {
  padding-top: 10px;

  .le-price-engine-options-main {
    padding-top: 10px;

    .le-price-engine-options-top {
      margin: 20px 0;
      width: 30%;

      .input-field-container {
        margin-bottom: 3px;
      }
    }

    .le-price-engine-options-loans {
      table {
        width: 100%;
        table-layout: fixed;
        margin: 10px 0;

        th:first-child,
        th:last-child {
          width: 50px;
        }

        td {
          min-width: 60px;
        }

        .input-field {
          width: 100%;
        }
      }

      .loan-processor-data-table {
        font-size: 12px;

        table {
          th:last-child {
            width: auto;
          }

          .input-field-container {
            width: 100%;

            .input-field {
              font-size: 12px;
              width: 100%;
            }
          }
        }
      }
    }
  }

  .le-price-engine-options-edit-loan {
    width: 50%;

    .input-field-container {
      margin-bottom: 3px;

      .input-field {
        width: 40%;
      }
    }

    .button {
      margin: 10px 3px 10px 0;
    }
  }
}

.loan-type {
  padding-top: 10px;

  .loan-type-main-page {
    padding-top: 10px;

    .loan-type-section {
      display: flex;

      .input-field {
        width: 60%;
        margin-bottom: 3px;
      }

      .button {
        margin: 10px 3px 10px 0;
      }

      .loan-type-header {
        margin: 10px 0;
        font-weight: bold;
      }
    }
  }

  .edit-loan-type {
    .input-field-container {
      margin-bottom: 3px;

      .input-field {
        width: 50%;
      }
    }

    .button {
      margin: 10px 3px 10px 0;
    }
  }
}

@import "../../../css/colors";

.reserves {
  padding-top: 10px;

  .reserves-middle-section {
    margin: 20px 0;
    width: 70%;
  }

  .reserves-borrower-entry {
    padding-bottom: 5px;

    table {
      width: 100%;
      table-layout: fixed;
      margin-bottom: 10px;

      .button {
        margin-right: 0;
      }

      th:nth-last-child(-n+2) {
        width: 45px;

        &:last-child {
          width: 60px;
        }
      }

      td {
        width: 12%;
      }

      .input-field {
        width: 100%;
      }
    }
  }

  .reserves-summary {
    table {
      width: 100%;

      td {
        white-space: nowrap;
      }

      .input-field {
        width: 100%;
      }
    }
  }

  .total-reserve-calculator {
    table {
      width: 100%;
    }

    .input-field {
      width: 100%;
    }

    .reserve-property-table {
      td {
        width: 11%;
      }
    }

    .total-reserve-calculator-jumbo-reserves {
      margin: 30px 0;

      th {
        text-align: left;
        width: 20%;
      }

      td {
        width: 16%;
      }
    }

    .total-reserve-calculator-bottom-table {
      th {
        text-align: left;
        width: 20%;
      }

      td {
        width: 16%;
      }
    }
  }

  .fnma-reserves-calculation {
    table {
      border-spacing: 0;
      border-collapse: collapse;
      width: 60%;
      border: 1px solid $black;
      margin-bottom: 15px;

      td {
        border: 1px solid $black;
        padding: 1px 3px;
      }
    }
  }

  .reserves-edit-reserve {
    .input-field-container {
      margin-bottom: 3px;
    }

    .button {
      margin: 10px 3px 10px 0;
    }

    .reserves-edit-reserve-title {
      height: 22px;
      margin-bottom: 3px;
      font-weight: bold;
    }
  }

  .reserves-equity-from-sale-calculator {
    table {
      width: 100%;

      table {
        td:first-child {
          width: 22%;
        }
      }
    }

    .input-field {
      width: 100%;
    }

    .button {
      margin: 10px 3px 10px 0;
    }
  }
}

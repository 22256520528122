@import "../../css/colors";

.sign-up {
  .main-title {
    font-size: 26px;
    color: $blue;
    text-align: center;
    margin-top: 5px;
  }

  .title {
    font-size: 14px;
    font-weight: bold;
    margin: 20px 0;
  }

  .error-messages {
    margin-top: 15px;
    color: $red;
  }

  .basic-info-section {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    
    .plan-title {
      margin-bottom: 20px;
    }

    .plan-promo-info {
      color: #0000ff;
      font-weight: bold;
    }

    .signup-payment-plans {
      width: 50%;

      .plan-price {
        margin-bottom: 20px;
        display: flex;
        align-items: flex-start;
    
        input {
          margin-right: 20px;
        }
      }
    }

    .signup-basic-info {
      .signup-basic-info-group {
        &:not(:last-child) {
          margin-bottom: 20px;
        }

        h4 {
          text-align: center;
        }
      }

      .billing-address-bottom-row {
        display: flex;
        flex-wrap: nowrap;

        .input-field-container {
          flex-basis: 50%;

          &:first-child {
            padding-right: 3px;
          }
        }
      }

      .input-field-container {
        margin-bottom: 3px;

        .input-field {
          width: 100%;
        }
      }
    }
  }

  .sign-up-receipt {
    .signup-receipt-payment-table {
      width: 650px;
      text-align: left;
  
      td, th {
        padding: 7px 0;
      }
    }
  }

  .sign-up-profile {
    .input-field-container {
      margin-bottom: 3px;

      .input-field {
        width: 50%;
      }
    }
  }

  .stripe-logo {
    text-align: right;
    padding: 50px 25px;
  }
  .stripe-logo img {
    width: 300px;
  }
}

@import './colors';
@import './mixins';

.button {
  display: inline-block;
  color: $white;
  background-color: $blue;
  padding: 0 12px;
  outline: none;
  border: none;
  border-radius: 5px;
  font-size: 12px;
  cursor: pointer;
  height: 22px;
  line-height: 22px;
  text-decoration: none;

  &:disabled {
    background-color: $darkGray;
  }

  &.inverse-button {
    color: $blue;
    border: 2px solid $blue;
    background-color: $white;
    line-height: 18px;
    font-weight: bold;
  }

  &.admin-button {
    color: $darkBlue;
    border: 2px solid $darkBlue;
    background-color: $white;
    line-height: 18px;
    font-weight: bold;

    &.active {
      background-color: $darkBlue;
      color: $white;
    }
  }
}

.input-field-container {
  @include inputContainer;
}

.mono-font {
  @include monoFont;
}

.half-width {
  width: 50%;
  margin-right: 3%;
}

.third-width {
  width: 33%;
  margin-right: 2%;
}

.forth-width {
  width: 25%;
  margin-right: 1%;
}

.fifth-width {
  width: 20%;
  margin-right: 1%;
}

.flex {
  display: flex;
}

.flex-start-with-front-spacing,
.flex-start-with-end-spacing {
  display: flex;
  justify-content: flex-start;
}

.flex-start-with-front-spacing > * {
  margin-left: auto;
}

.flex-start-with-end-spacing > * {
  margin-right: auto;
}

.align-items-center {
  align-items: center;
}

.flex-reverse-direction {
  flex-direction: row-reverse;
}

.flex-direction-column {
  display: flex;
  flex-direction: column;
}

.flex-space-between {
  display: flex;
  justify-content: space-between;
}

.flex-space-evenly {
  display: flex;
  // IE11 fallback
  justify-content: space-around;
  justify-content: space-evenly;
}

.flex-grow {
  flex-grow: 1;
}

.justify-content-center {
  justify-content: center;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
}

.left-align {
  text-align: left !important;
}

.right-align {
  text-align: right !important;
}

.center-align {
  text-align: center !important;
}

.bold {
  font-weight: bold;
}

.italic {
  font-style: italic;
}

.pointer {
  cursor: pointer;
}

.divider {
  height: 5px;
  border-bottom: 1px solid $darkGray;
}

.gray-bg {
  background-color: $gray;
}

.green-text {
  color: $darkGreen;
}

.red-text {
  color: $red;
}

.error-messages {
  padding: 15px 0;
  color: $red;
}

.top-header-section {
  height: 32px;
  display: flex;
  justify-items: flex-start;
  align-items: center;
  background-color: $gray;
  padding: 0 20px;

  .top-header-section-title {
    color: $darkerGray;
    font-size: 15px;
    font-weight: bold;
    margin-right: 20px;
  }

  .button {
    margin-right: 3px;
  }

  .fnm-help-link {
    margin-left: 5px;
    text-decoration: none;
  }
}

.code-block {
  @include monoFont;
  display: block;
  padding: 10px;
  margin: 10px 0;
  font-size: 12px;
  color: $black;
  background-color: $lightGray;
}

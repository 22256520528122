.other-considerations .content-section:not(:first-of-type) .content-title {
  flex-basis: 150px;
}

.other-considerations .content-section .selections {
  line-height: 16px;
  margin-right: 2px;
}

.other-considerations .content-section .selections select,
.other-considerations .content-section .selections select option {
  background-color: #eef3f8;
  outline: none;
  text-align-last: center;
  text-align: center;
  -ms-text-align-last: center;
  -moz-text-align-last: center;
}

.other-considerations .content-section .selections select {
  border: thin solid #000000;
  height: 22px;
  width: 75px;
}

.other-considerations .content-section .selections > div:first-child select {
  border-bottom: none;
}

@media print {
  .other-considerations .content-section .selections {
    color-adjust: exact;
    background-color: #eef3f8;
    border: thin solid #000000;
  }

  .other-considerations .content-section .selections > div {
    height: 20px;
    width: 75px;
    text-align: center;
    line-height: 20px;
  }

  .other-considerations .content-section .selections > div:first-child {
    border-bottom: thin solid #000000;
  }
}

@import '../../css/colors';

.login-page {
  padding: 30px 10px;

  .login-title {
    display: flex;
    justify-content: center;
    font-weight: bold;
    font-size: 20px;
  }

  .login-section {
    margin: 10px auto;

    .login-action {
      width: 400px;

      .input-field-container {
        margin-bottom: 3px;

        .input-field {
          width: 70%;
        }
      }
    }

    .error-message {
      color: $red;
      height: 22px;
      min-width: 22px;
    }
  }

  .sign-up-section {
    margin: 30px 0;

    .sign-up-content {
      width: 400px;
      text-align: right;
    }
  }

  .login-disclaimer {
    margin: 50px 40px 20px;
    font-size: 11px;
  }
}

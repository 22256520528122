@import '../../../css/colors';

.le-price-engine {
  padding-top: 10px;

  .le-price-engine-title {
    font-weight: bold;
    margin: 10px 0;
  }

  .le-price-engine-main {
    padding-top: 10px;

    .le-price-engine-main-top {
      .le-price-engine-main-warning {
        margin-bottom: 10px;
        font-weight: bold;
        color: $red;
      }

      .input-field {
        width: 40%;
      }
    }

    .input-field-container {
      margin-bottom: 3px;
    }

    .le-price-engine-main-loans {
      .input-field-container {
        font-size: 11px;

        .input-field {
          font-size: 11px;
          width: 50%;
        }
      }
    }
  }

  .le-price-engine-edit-loan {
    .input-field-container {
      margin-bottom: 3px;
    }

    .button {
      margin: 10px 3px 10px 0;
    }

    .le-price-engine-rate-calculator {
      margin: 20px 0;
    }
  }
}

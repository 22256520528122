@import '../../../css/mixins';

.currency-field-container {
  .currency-field {
    @include input;
    @include monoFont;
    text-align: right;
    transition: all 0.3s ease-in-out;
  }
}

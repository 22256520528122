@import '../../css/colors';

.pipeline-page {
  width: 100%;

  .pipeline-section-nav {
    display: flex;
    margin-top: 10px;

    .pipeline-section-picker {
      margin-right: 10px;
      border-radius: 5px;
      border: 2px solid $darkGray;
      color: $darkGray;
      padding: 0 12px;
      height: 22px;
      line-height: 18px;
      cursor: pointer;

      &.time-sensitive {
        border-color: $pink;
      }

      &.active {
        color: $darkBlue;
        border-color: $blue;

        &.time-sensitive {
          color: $red;
          border-color: $red;
        }
      }
    }
  }

  .pipeline-filters {
    margin: 20px 0;
    display: flex;
    justify-content: flex-end;

    .input-field-container {
      width: 30%;
    }
  }

  .pipeline-list {
    margin: 10px 0;
    font-size: 12px;
    line-height: 21px;

    .input-field-container {
      .input-field,
      .input-field .date-field-input {
        font-size: 12px;
        height: 20px;
      }
    }

    .pipeline-entry-container {
      .pipeline-entry-title {
        margin-bottom: 1px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .button {
          font-size: 14px;
        }

        .time-sensitive-badge {
          color: $red;
          height: 100%;
          font-weight: bold;
        }
      }

      .pipeline-entry {
        display: flex;
        flex-direction: column;
        border: 2px solid $blue;
        margin-bottom: 12px;

        .pipeline-entry-row {
          display: flex;
          justify-content: flex-start;
          padding: 2px 0 5px 0;

          &:not(:first-child) {
            border-top: thin solid $gray;
          }

          .pipeline-entry-cell {
            flex-grow: 1;
            width: 0;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: center;
            margin: 0 5px;
            font-weight: bold;

            & > div {
              height: 20px;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
              max-width: 100%;
            }

            .entry-title {
              font-weight: normal;
              height: 18px;
            }
          }
        }
      }
    }
  }

  .right-angle-box {
    display: flex;
    height: 20px;
    position: relative;
    overflow: hidden;
    padding-right: 12px;

    .box-content {
      position: relative;
      height: 100%;
      width: 86px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $blue;
      color: $white;
      font-weight: normal;
    }

    &:before {
      box-sizing: border-box;
      position: absolute;
      content: " ";
      right: 0;
      top: -2px;
      border-left: 12px solid $blue;
      border-top: 12px solid transparent;
      border-bottom: 12px solid transparent;
    }

    &.inverse-right-angle-box:not(.unfilled-right-angle-box)  {
      .box-content {
        border-width: 2px 0 2px 2px;
        border-style: solid;
        border-color: $blue;
        background-color: $white;
        color: $red;

        &:after {
          box-sizing: border-box;
          position: absolute;
          content: " ";
          width: 1px;
          right: -1px;
          top: -2px;
          height: 20px;
          background-color: $white;
          border-top: 2px solid $blue;
          border-bottom: 2px solid $blue;
        }
      }

      &:after {
        box-sizing: border-box;
        position: absolute;
        content: " ";
        right: 3px;
        top: 2px;
        border-left: 8px solid $white;
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
      }
    }

    &.unfilled-right-angle-box {
      .box-content {
        background-color: $gray;
      }

      &:before {
        border-left-color: $gray;
      }
    }
  }

  .create-name-modal-bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);

    .create-name-modal {
      position: absolute;
      left: 50%;
      top: 10%;
      transform: translateX(-50%);
      background-color: $white;
      padding: 20px;
      border: 2px solid $blue;
      text-align: right;

      .input-field-container {
        width: 450px;

        &.import-file-field {
          margin-top: 5px;
        }

        .input-field {
          flex-grow: 1;
          margin-left: 10px;
        }
      }

      .button {
        margin: 10px 3px 0 0;
      }
    }
  }

  .loan-status-container {
    .green-status {
      color: $darkGreen;
      background-color: $green;
    }

    .inverse-green-status {
      color: $white;
      background-color: $darkGreen;
    }

    .red-status {
      color: $red;
      background-color: $pink;
    }

    .red-on-white-status {
      color: $red;
      background-color: $white;
    }

    .yellow-status {
      color: $black;
      background-color: $yellow;
    }
  }
}

@import "../../../css/colors";

.loan-tool-controls {
  .loan-tool-controls-title {
    display: flex;
    justify-content: center;
    padding-bottom: 2px;

    .edit-document-name {
      .text-field-container {
        display: inline-flex;

        label {
          display: none;
        }

        .edit-document-name-text-field {
          height: 16px;
          width: 200px;
        }
      }
    }

    .edit-document-name-button {
      padding: 0 6px;
      height: 16px;
      line-height: 16px;
      margin-left: 6px;
    }
  }

  .loan-tool-controls-bar {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 32px;
    padding: 0 20px;
    background-color: $gray;

    & > .button {
      margin-left: 10px;
    }

    .loan-tool-left {
      flex-grow: 1;
      display: flex;
      align-items: center;

      .loan-tool-type {
        color: $darkerGray;
        font-weight: bold;
        font-size: 15px;
        margin-right: 20px;
      }

      .borrower-names {
        display: flex;
        color: $darkerGray;
        font-size: 12px;

        & > div {
          margin-right: 20px;
        }
      }
    }

    .my-loans {
      text-decoration: none;
      color: $darkerGray;
      font-weight: bold;
      font-size: 13px;
      margin-left: 20px;
    }

    .fnm-help-link {
      margin-right: 5px;
      text-decoration: none;
    }
  }
}

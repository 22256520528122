@import '../../css/colors';

.company-admin-panel {
  .top-header-section {
    position: relative;

    .subscription-panel {
      display: inline-block;
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .company-info {
    .input-field-container {
      margin-bottom: 3px;

      .input-field {
        width: 50%;
      }
    }

    .button {
      margin-top: 10px;
    }
  }

  .user-management {
    .button {
      margin-right: 3px;
    }

    table {
      width: 100%;
      border-collapse: collapse;
      margin-top: 10px;

      th {
        font-weight: bold;
        text-align: left;
      }

      td {
        padding: 3px;
        text-align: left;
        border: 1px solid $blue;
      }

      .subaccount {
        .subaccount-arrow {
          margin: 0 10px;
        }
      }
    }

    .user-role {
      display: inline-block;
      margin-left: 5px;
      color: $darkBlue;
      font-weight: bold;
      background-color: $gray;
      padding: 2px 6px;
    }
  }

  .edit-user {
    .button {
      margin-right: 3px;
    }

    .input-field-container {
      margin-bottom: 3px;

      .input-field {
        width: 50%;
      }
    }
  }

  .unsubscribe {
    .button {
      margin-left: 3px;
    }
  }
}

.schedule-e-calculator {
  padding-top: 10px;

  .input-field-container, .button {
    margin-bottom: 3px;
    margin-right: 3px;
  }

  .tax-returns {
    margin-bottom: 10px;

    .tax-return-label {
      height: 22px;
      line-height: 22px;
      margin-bottom: 3px;
    }

    .tax-return-totals {
      margin-top: 5px;
    }
  }
}

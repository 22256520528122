@import "../../../css/colors";

.invest-reo {
  padding-top: 10px;

  .invest-reo-table {
    width: 100%;
    table-layout: fixed;
    font-size: 12px;
    margin-bottom: 3px;

    th {
      vertical-align: bottom;
      font-size: 12px;
      width: auto;

      &:last-child {
        width: 50px;
      }
    }

    td {
      .input-field-container {
        .input-field {
          width: 100%;
          font-size: 12px;
        }
      }
    }
  }

  .investment-calculator {
    .input-field-container {
      margin-bottom: 3px;

      .input-field {
        width: 50%;
      }
    }

    .button {
      margin-right: 3px;
    }
  }
}

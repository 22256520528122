@import '../../../css/colors';
@import '../../../css/mixins';

.loan-tool-navigation {
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: 12px;

  .nav-connector-line {
    position: absolute;
    margin: 0;

    .nav-connector-line-top {
      position: absolute;
      top: -1px;
      left: 0;
      width: 100%;
      height: calc(50% + 2px);
      border-left: 2px $blue solid;
      border-bottom: 2px $blue solid;
    }

    .nav-connector-line-bottom {
      position: absolute;
      top: calc(50% - 1px);
      left: 0;
      width: 100%;
      height: calc(50% + 1px);
      border-right: 2px $blue solid;
    }

    &.nav-connector-right-oriented {
      .nav-connector-line-top {
        border-left: none;
        border-right: 2px $blue solid;
      }

      .nav-connector-line-bottom {
        border-right: none;
        border-left: 2px $blue solid;
      }
    }
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
  }

  li {
    height: 24px;
    line-height: 22px;
  }

  .nav-bar {
    margin-top: 10px;
  }

  .sub-nav {
    margin-left: 110px;
  }

  .client-info {
    width: 110px;
    text-align: center;
    border: 2px $darkerGray solid;
    border-radius: 5px;
    color: $black;
  }

  .loan-details {
    @extend .client-info;
    background-color: $gray;
  }

  .nav-button {
    display: flex;
    position: relative;
    align-items: center;

    & > a {
      display: block;
      text-decoration: none;
      text-align: center;
      border: 2px $gray solid;
      border-radius: 5px;
      width: 144px;
      height: 100%;
      color: $darkGray;

      &:after {
        position: absolute;
        content: " ";
        width: 16px;
        height: 16px;
        border-radius: 8px;
        background-color: $gray;
        right: 4px;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .button {
      height: 100%;
    }

    .nav-arrow {
      @include navArrow;
      display: none;
    }

    .nav-connector {
      position: relative;
      width: 25px;
      height: 16px;
      line-height: 24px;
      margin-left: -1px;
      margin-right: -1px;
      background-color: $gray;
      z-index: -1;
    }

    &.active {
      & > a {
        color: $darkBlue;
      }

      .nav-arrow {
        display: flex;
      }

      .nav-connector {
        display: none;
      }

      & > a {
        border-color: $blue;
      }

      & > a:after {
        background-color: $blue;
      }
    }

    &.marked {
      .nav-arrow {
        display: flex;
      }

      .nav-connector {
        display: none;
      }

      & > a:after {
        background-color: $green;
      }
    }
  }
}

@import '../../css/colors';

.subscribe-page {
  .main-title {
    font-size: 26px;
    color: $blue;
    text-align: center;
    margin: 5px 0;
  }

  .content {
    margin-top: 20px;
  }
}

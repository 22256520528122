@import '../../../css/mixins';

.checkbox-field-container {
  .checkbox-field {
    display: flex;
    align-items: center;

    input {
      margin: 0;
    }
  }
}
